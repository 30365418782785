import React, { useState } from "react";
import { graphql } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import Breadcrumbs from "../components/breadcrumbs"
import TableOfContents from '../components/table-of-contents';
import Layout from "../components/layout";
import Posts from "../components/posts";
import Icon from "../components/icon";
import Translate from "../components/translate";
import CommentsForm from "../components/comments-form";
import Faq from "../components/faq";

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";

export const Head = ({ ...props }) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/blog/${props.pageContext.mysqlId}.png`} />}
  </BaseHead>
);

const Debt = ({
  data: { mysqlBlog, mysqlTerms, allMysqlBlog, allMysqlFaq },
  pageContext: { breadcrumbs },
  location: { pathname }
}) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const [isFaq, setIsFaq] = useState(false);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)

  return (
    <Layout pathname={pathname}>
      <div className={b.pbMd4}>
        <section className={`intro-products2 ${b.py4} ${b.pyMd5} `}>
          <div className={[b.container, "breadcrumbs-mobile-products", b.pb3, "scroll-inner"].join(" ")}>
            <Breadcrumbs items={breadcrumbs} />
          </div>
          <div className={`${b.container} ${b.pb2} ${b.positionRelative}`}>
            <div className={b.row}>
              <div className={[b.colLg9, b.textWhite].join(' ')}>
                <h1 className={` ${b.pb3} ${b.ptLg4} big-title ${b.textWhite}`}>{mysqlTerms.title}</h1>
                <div className={b.textWhite } dangerouslySetInnerHTML={{ __html: mysqlBlog.intro }} />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className={[b.container, b.pbLg5, b.pt3].join(" ")}>
        {mysqlBlog.body && <div className={`${b.row} product-page blog-content`}>
          <section className={`${b.colXl8} blackToGray dark-theme-listing pth2 ${b.mb3}`}>
            <BlogContent content={mysqlBlog.body} />

            {allMysqlFaq?.nodes.length > 0 && (
              <>
                <div className="post-content">
                  <h2>
                    <Translate tKey="Often asked questions" />
                  </h2>

                  {allMysqlFaq.nodes.map(({ title, text }, i) => (
                    <Faq title={title} text={text} key={i}/>
                  ))}

                  <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                    <button className={[b.btn, "btn-light", "btn-cta", "button-256",].join(" ")} onClick={() => { toggleForm(); toggleFaq(true); }}>
                      <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")} />
                      <Translate tKey="Ask your own question" />
                    </button>
                  </div>
                </div>
              </>
            )}
          </section>

          <TableOfContents selector=".post-content h2[id]" />

        </div>}

        {allMysqlBlog.nodes.length > 0 && <aside className={[b.my5, b.ptLg5].join(" ")}>
          <h2 className={["title", b.dBlock, b.mb4, b.mt3].join(' ')}>Treści powiązane z {mysqlBlog.title}</h2>
          <Posts items={allMysqlBlog.nodes} />
        </aside>}

        <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false); toggleFaq(false); }} formID={mysqlTerms.mysqlId} commentType="entry" faq={isFaq} />
      </div>
    </Layout>
  )
}

export default Debt

export const query = graphql`
query Debt($mysqlId: Int!, $term_id: Int!, $withoutUrls: [String]) {
  mysqlBlog(mysqlId: {eq: $mysqlId}) {
    mysqlId
    body
    intro
    title
  }
  mysqlTerms(mysqlId: {eq: $term_id}) {
    description
    title
    term
    term_normalized
    description_alt
    path
  }
  allMysqlBlog(filter: {url: {nin: $withoutUrls}, mysqlId: {ne: $mysqlId}, blogTerms:{elemMatch:{type: {eq: "category"}, mysqlId: {eq: $term_id}}}, description: {ne: null}, status: {eq: "publish"}}) {
    nodes {
      ...PostData
    }
  }
  allMysqlFaq(
    filter: { item: { eq: $mysqlId }, type: { eq: "article" }, answer: { ne: null } },
    sort: { fields: priority, order: DESC }
  ) {
    nodes {
      title: question
      text: answer
      priority
    }
  }
  allMysqlSeo(filter: { item_id: { eq: $term_id }, item_type: { eq: "term" }}) {
    nodes {
      ...SeoData
    }
  }
}
`