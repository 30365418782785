import { useState } from 'react'
import PropTypes from 'prop-types'

const Charger = ({ children, countItem, step, list }) => {
  const [count, setCount] = useState(countItem)

  const isLoaded = list.length >= count + 1;
  const loadMore = () => {
    if (isLoaded) {
      setCount(Math.min(list.length, count + step))
    }
  }

  return children({
    loadMore,
    isLoaded,
    count,
    customList: list.slice(0, count),
  })
}

Charger.propTypes = {
  step: PropTypes.number.isRequired,
  countItem: PropTypes.number.isRequired,
  list: PropTypes.array.isRequired,
}

export default Charger;