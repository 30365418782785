import React, { useMemo, useState } from 'react';
import * as b from "../../../bootstrap.module.css";
import InputNumber from '../../input-number';
import AllProducts from '../../../all-products';
import Icon from '../../../icon';

const CreditworthinessCalculator = () => {
  const [monthlyIncome, setMonthlyIncome] = useState(6_000);
  const [monthlyCosts, setMonthlyCosts] = useState(1_000);
  const [allInstallment, setAllInstallment] = useState(0);
  const [cardLimits, setCardLimits] = useState(0);
  const [familyCount, setFamilyCount] = useState(2);
  const [interest, setInterest] = useState(9);
  const [paymentYears, setPaymentYears] = useState(25);
  const [installmentType, setInstallmentType] = useState('equal');
  const [isExtendedView, setIsExtendedView] = useState(false);
  const [details, setDetails] = useState(null);

  const settings = useMemo(() => ({
    types: 'credits', 
    subtypes: { credits: '1' }, 
    limit: 7,
    input: {
      amount: details?.capacity, 
      time: paymentYears * 12,
    },
  }), [details?.capacity, paymentYears]);

  const clearDetails = (e) => {
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'BUTTON' || e.target.tagName === 'SELECT') {
      setDetails(null);
    }
  };

  const updateDetails = async () => {
    if (details) {
      return;
    }

    const data = new FormData();
    data.append('dm', monthlyIncome);
    data.append('zs', monthlyCosts);
    data.append('rk', allInstallment);
    data.append('kk', cardLimits);
    data.append('lo', familyCount);
    data.append('pr', interest);
    data.append('os', paymentYears);
    data.append('tr', installmentType);
    
    try {
      const response = await fetch('https://cms.ofin.pl/api/credit/calc', {
        method: 'POST',
        body: data,
      });
      const json = await response.json();
    
      setDetails({ capacity: json.zk || -1, });
    } catch (error) {
      console.dir(error);
    }
  };

  const toggleExtendedView = (e) => {
    setIsExtendedView(!isExtendedView);
    clearDetails(e);
  };

  const changeInstallmentType = (e) => {
    setInstallmentType(e.target.value);
  };
  
  return (
    <form 
      className={["product-inputs", "credit-calculator", "blueToGray", b.mt4, b.p3].join(" ")} 
      onSubmit={(e) => e.preventDefault()}
    >
      <h2 className={b.mb3} style={{ fontWeight: 500 }}>Jak obliczyć zdolność kredytową i wysokość raty kredytu hipotecznego?</h2>
    
      <div className={b.row}>
        <div 
          className={[b.colMd6, b.colXl4, b.order1].join(' ')} 
          onFocusCapture={clearDetails}
        >
          <label htmlFor="monthy-income" className={b.mb2}>
            Miesięczny dochód netto:
          </label>

          <InputNumber
            id="monthy-income"
            minValue={100}
            value={monthlyIncome} 
            maxValue={30_000}
            updateValue={setMonthlyIncome} 
            step={100}
            suffix="zł"
          />
        </div>

        <div 
          className={[b.colMd6, b.colXl4, b.order2, b.mt3, b.mtMd0].join(' ')} 
          onFocusCapture={clearDetails}
        >
          <label htmlFor="monthy-costs" className={b.mb2}>
            Stałe miesięczne zobowiązania:
          </label>

          <InputNumber
            id="monthy-costs"
            minValue={0}
            value={monthlyCosts} 
            maxValue={10_000}
            updateValue={setMonthlyCosts} 
            step={100}
            suffix="zł"
          />
        </div>

        <div className={[b.colMd12, b.colXl4, b.orderLast, b.orderXl3, b.alignSelfEnd, b.mt3, b.mtXl0].join(' ')}>
          <button 
            className={[b.btn, b.btnSuccess, "btn-success", "btn-cta", b.my0].join(" ")}
            onClick={updateDetails}
          >
            <Icon size={25} icon="Calculator-mini" className={[b.mr2, "icon-width"].join(" ")} />

            Oblicz
          </button>
        </div>

        <div 
          className={[b.colMd6, b.colXl4, b.order4, b.mt3].join(' ')} 
          onFocusCapture={clearDetails}
        >
          <label htmlFor="all-installment" className={b.mb2}>
            Suma rat spłacanych kredytów:
          </label>

          <InputNumber
            id="all-installment"
            minValue={0}
            value={allInstallment} 
            maxValue={10_000}
            updateValue={setAllInstallment} 
            step={100}
            suffix="zł"
          />
        </div>

        <div 
          className={[b.colMd6, b.colXl4, b.order5, b.mt3].join(' ')} 
          onFocusCapture={clearDetails}
        >
          <label htmlFor="family-count" className={b.mb2}>
            Liczba osób na utrzymaniu:
          </label>

          <InputNumber
            id="family-count"
            minValue={0}
            value={familyCount} 
            maxValue={30}
            updateValue={setFamilyCount} 
            step={1}
            suffix=""
          />
        </div>

        <div 
          className={[b.colMd6, b.colXl4, b.order6, b.mt3].join(' ')}
          onFocusCapture={clearDetails}
        >
          <label htmlFor="payments-years" className={b.mb2}>
            Na ile lat chcesz zaciągnąć kredyt?
          </label>

          <InputNumber
            id="payments-years"
            minValue={5}
            value={paymentYears} 
            maxValue={30}
            updateValue={setPaymentYears} 
            step={1}
            suffix=""
          />
        </div>

        <div className={[b.col12, b.order7, b.mt3].join(' ')}>
          <button className="toggle-calculator-view blueToGray" onClick={toggleExtendedView}>
            {isExtendedView ? 'Ustawienia podstawowe' : 'Ustawienia zaawansowane'}

            {isExtendedView && <Icon size={17} icon="Angle-up" className={['icon-blog', b.ml1, b.mr0].join(' ')} />}
            {!isExtendedView && <Icon size={17} icon="Angle-down" className={['icon-blog', b.ml1, b.mr0].join(' ')} />}
          </button>
        </div>

        {isExtendedView && <>
          <div 
            className={[b.colMd6, b.colXl4, b.order8, b.mt2].join(' ')} 
            onFocusCapture={clearDetails}
          >
            <label htmlFor="card-limits" className={b.mb2}>
              Limity na kartach kredytowych:
            </label>

            <InputNumber
              id="card-limits"
              minValue={0}
              value={cardLimits} 
              maxValue={100_000}
              updateValue={setCardLimits} 
              step={100}
              suffix="zł"
            />
          </div>
        
          <div 
            className={[b.colMd6, b.colXl4, b.order9, b.mt3, b.mtMd2].join(' ')}
            onFocusCapture={clearDetails}
          >
            <label htmlFor="interest" className={b.mb2}>
              Oprocentowanie roczne:
            </label>

            <InputNumber
              id="interest"
              minValue={0.01}
              value={interest} 
              maxValue={30}
              updateValue={setInterest} 
              step={0.01}
              suffix="%"
            />
          </div>

          <div 
            className={[b.colMd6, b.colXl4, b.order10, b.mt3, b.mtMd2].join(' ')}
            onFocusCapture={clearDetails}
          >
            <label htmlFor="installment-type" className={b.mb2}>
              Typ rat:
            </label>

            <select 
              id="installment-type" 
              className={["select", b.py2, b.card].join(" ")}
              value={installmentType} 
              onChange={changeInstallmentType} 
            >
              <option value="equal">Równe</option>
              <option value="decreas">Malejące</option>
            </select>
          </div>
        </>}
      </div>

      {details && (<>
        <div className={['details', b.mt4, b.p3].join(' ')}>
          <div className={b.w100}>
            <h3 className={[b.textLeft, b.mb3].join(' ')}>Wynik obliczeń</h3>

            {details.capacity > 0 && <p><strong style={{ fontSize: '20px' }}>Maksymalna zdolność kredytowa {parseInt(details.capacity)} zł</strong></p>}
            {details.capacity <= 0 && <p><strong style={{ fontSize: '20px' }}>Brak zdolności kredytowej</strong></p>}         
          </div>
        </div>

        {details.capacity > 30_000 && (<>
          <h3 className={[b.mt4, b.mb3].join(' ')}>Zobacz oferty na kwotę {details.capacity} zł i okres spłaty {paymentYears * 12} miesięcy</h3>
          <AllProducts settings={settings} />
        </>)}
      </>)}
    </form>
  );
};

export default CreditworthinessCalculator;
