import { useStaticQuery, graphql } from 'gatsby'
import * as b from '../components/bootstrap.module.css'

const lexiconConfig = {
  isInitialized: false,
  keys: {}
};

const GetConfig = () => {
  const { allMysqlConfig } = useStaticQuery(graphql`
    query Config2 {
        allMysqlConfig {
            nodes {
                name
                value
            }
        }
    }
  `);

  if (!lexiconConfig.isInitialized) {
    for (let i = 0; i < allMysqlConfig.nodes.length; i++) {
      const { name, value } = allMysqlConfig.nodes[i];
      lexiconConfig.keys[name] = value;
    }
  }

  const getConfig = (key, default_value) => {
    const value = lexiconConfig.keys[key];
    if (!value) return default_value;
    if (value.indexOf('bootstrap.') >= 0) {
      const args = value.split('.');
      if (args.length < 2) return default_value;
      if (!b[args[1]]) return default_value;
      return b[args[1]];
    }
    return value;
  }
  return getConfig;
}

export default GetConfig