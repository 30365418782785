import React from 'react'
import Thumbnail from '../components/thumbnail'
import * as b from "../components/bootstrap.module.css"
import { graphql, useStaticQuery, Link } from 'gatsby'
import Icon from "../components/icon.tsx"
import resolveUrl from '../utils/urls/resolve-url';

const Team = ({ children }) => {
  const { allMysqlTeam } = useStaticQuery(graphql`
    query Team {
      allMysqlTeam(sort: { fields: mysqlId, order: ASC })  {
        nodes {
          name
          title
          mysqlId
          mysqlImage {
            childImageSharp {
              gatsbyImageData(width: 150)
            }
          }
        }
      }
    }
  `)

  return (
    <section className={['team', `team-white`, b.mt4, b.mtLg5].join(' ')}>
      <div className={b.container}>
        {children}
        
        <div className={b.row}>
          {
            allMysqlTeam.nodes.map(({ name, title, mysqlImage }, i) => (
              <article key={i} className={[b.colLg6, b.colXl3, b.mb4].join(' ')}>
                <div className={[b.px3, "team-person product-box"].join(' ')}>
                  <div className={[b.row, b.alignItemsCenter].join(' ')}>
                    <div className={b.colAuto}>
                      <Thumbnail image={mysqlImage} className="team-person-image" alt={name}/>
                    </div>
                    <div className={[b.col, b.pl2].join(' ')}>
                      <Link to={resolveUrl('redakcja', name)}>
                        <span className={['tp-name', 'teamTextColor'].join(' ')}>{name}</span>
                      </Link>
                      <p className={['team-person-title', b.mb0,b.fontWeightNormal,b.mt1].join(' ')}>
                        <span className="team-person-job">{title}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </article>
            ))
          }
        </div>
      </div>
    </section>
  )
} 

export default Team
