import resolveUrl from "./resolve-url";

const resolveLocalfirmUrl = ({ bank, ...localfirm }) => {
  return bank.specific_url 
    ? resolveUrl(bank.specific_url, localfirm.url) 
    : resolveUrl(
      bank.prefix_url,
      bank.url,
      localfirm.url,
    );
}

export default resolveLocalfirmUrl;