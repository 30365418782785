import React from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import TableOfContents from '../components/table-of-contents';
import Breadcrumbs from "../components/breadcrumbs"
import Thumbnail from "../components/thumbnail";
import resolveUrl from "../utils/urls/resolve-url";
import Layout from "../components/layout";
import Posts from '../components/posts';
import useMedia from "../hooks/useMedia";

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const DebtorsDatabases = ({ 
  data: { debtorsFirms, allMysqlBlog, mysqlTerms }, 
  pageContext: { breadcrumbs },
  location: { pathname },
}) => {
  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);
  return (
    <Layout pathname={pathname}>
      {isMobileResolution ? (
        <div className={['breadcrumbs-mobile-2', b.ptLg5, "scroll-inner"].join(' ')}>
          <Breadcrumbs items={breadcrumbs} theme='white'/>
        </div>
      )
      : (<div className={[b.container,'breadcrumbs-mobile-2', b.py3, b.ptLg5, "scroll-inner", b.px0].join(' ')}>
          <Breadcrumbs items={breadcrumbs} theme='white'/>
        </div>
        )
      }
    <div className={[b.container, b.pbLg5, b.pt3].join(" ")}>
      <div className={["blueToGray", b.mb4].join(" ")}>
        <h1 className={['big-title', b.mb3].join(" ")}>{mysqlTerms.title}</h1>
        <BlogContent content={mysqlTerms.description} className={[b.mb3,'font-size-20',b.fontWeightNormal].join(' ')}/>
      </div>
      <div className={[b.row].join(' ')}>
        {debtorsFirms.nodes.map(({title,published_date,description,image_description,term_normalized,terms_path,url,mysqlImages}, i) => (
          <div className={[b.colMd6, b.colLg4, b.colXl3, b.mb4, b.pbLg2].join(' ')} key={i} >
            <div className={['bank-list-products-box',b.mt3].join(' ')} style={{ height: '100%' }}>
              <div className={['debtors-box','dark-mode-image'].join(' ')}>
                  <Thumbnail image={mysqlImages[0]} alt={image_description || ''} />
              </div>
              <div className="post-body" style={{ height: '100%' }}>
                <div>
                  <span className={["post-text",'font-size-10','post-text-color'].join(' ')}>{published_date}</span>
                  <div className={[b.h6,'font-size-18', b.mb3, b.mt1].join(' ')}>
                    <Link to={resolveUrl(terms_path,term_normalized,url)} className={["post-link","post-heading-color"].join(' ')}>{title}</Link>
                  </div>
                  <p className={['post-text','post-text-color', b.mb0].join(' ')}>
                    {description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {(mysqlTerms.description || mysqlTerms.description_additional || mysqlTerms.description_alt) && <div className={`${b.row} product-page blog-content`}>
        <section className={`${b.colXl8} blackToGray dark-theme-listing pth2`}>
          {<BlogContent content={`${mysqlTerms.description_alt} ${mysqlTerms.description_additional}`} />}
        </section>

        <TableOfContents selector=".post-content h2[id]" />
      </div>}
      
      {allMysqlBlog.nodes.length > 0 && <aside className={[b.my5, b.ptLg5].join(" ")}>
        <h2 className={["title", b.dBlock, b.mb4,b.mt3].join(' ')}>Treści powiązane z {mysqlTerms.term}</h2>
        <Posts items={allMysqlBlog.nodes} />
      </aside>}
    </div>
    </Layout>
  )
}

export default DebtorsDatabases

export const query = graphql `
query DebtorsDatabeses($mysqlId: Int!, $withoutUrls: [String]) {
  debtorsFirms: allMysqlBlog(filter: {cluster: {eq: "debtors"}, related_categories: {eq: ""} status: {eq: "publish"}}) {
    nodes {
      mysqlImages {
        childImageSharp {
          gatsbyImageData(width: 295)
        }
      }
      title
      published_date
      description
      image_description
      term_normalized
      terms_path
      url
    }
  }
  allMysqlBlog(
    filter: {
      url: {nin: $withoutUrls}, 
      blogTerms:{elemMatch:{type: {eq: "category"}, mysqlId: {eq: $mysqlId}}}, 
      cluster: {ne: "debtors"}, 
      description: {ne: null}, 
      status: {eq: "publish"}
    }
  ) {
    nodes {
      ...PostData
    }
  }
  mysqlTerms(mysqlId: {eq: $mysqlId}) {
    description
    title
    term
    term_normalized
    description_alt
    description_additional
    path
  }
  allMysqlSeo(filter: { item_id: {eq: $mysqlId}, item_type: { eq: "term" }}) {
    nodes {
      ...SeoData
    }
  }
}
`