import { Link } from "gatsby";
import React, { useState } from "react";
import PropsType from "prop-types";
import * as b from "../../components/bootstrap.module.css";
import Icon from "../../components/icon.tsx";
import Thumbnail from "../../components/thumbnail";
import Translate from "../../components/translate";
import resolveProductUrl from "../../utils/urls/resolve-product-url";

const creditValues = {
  1: { icon: 'Mortgage', text: 'Mortgage' },
  2: { icon: 'Cash-loan', text: 'Cash credit' },
  3: { icon: 'Car-loan', text: 'Car loan' },
  4: { icon: 'Consolidation-loan', text: 'Consolidation loan' },
  5: { icon: 'Deposits', text: 'Revolving loan' },
};
const accountValues = {
  1: { icon: 'Bank-account', text: 'Personal account' },
  2: { icon: 'Piggy-bank', text: 'Savings account' },
  4: { icon: 'Junior-account', text: 'Account for junior' },
  5: { icon: 'Branches', text: 'Foreign currency account' },
  6: { icon: 'Investment', text: 'Deposit' },
};

const BankListItem = ({credits, accounts, card, name, rating, inactive_reason, i, mysqlImage,bankPromoUrl , ...bank}) => {
  const countProduct = (credits?.length || 0) + (accounts.length || 0)
  const isOne = countProduct === 1;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={[b.colMd6, b.colXl4, b.mb3, b.mbLg4,isOpen === i ? "bank-list-box-open" : b.h100].join(" ")}>
    <div className={[b.card, "post-bank-list", b.py3].join(" ")}>
      <div>
        <div className={[b.row,b.mx0,b.alignItemsCenter,].join(" ")} style={{ height: "90px" }}>
          <h2 className={[b.h5,b.col7,b.mb0,].join(" ")}>
            <Link to={resolveProductUrl(bank)} className="blackToWhite">
              {name}
            </Link>
          </h2>
          <div className={[b.col5, b.pl2].join(" ")}>
            <div className={[`dark-mode-image blog-promotions-info-box ${inactive_reason ? 'grayscale' : ''}`].join(' ')}>
              <Thumbnail image={mysqlImage} style={{ maxHeight: "60px" }} alt={name + ' | ofin.pl'}/>
            </div>
          </div>
        </div>
        <div className={b.px3}>
          {inactive_reason&& 
            <div className={["firm-row-likes",b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb1].join(' ')}>
              <Icon size={16} color="#B93838" icon="Info" className={b.mr1} />
              <p className={['font-size-13',b.mb0,'tp-name'].join(' ')} style={{ color: '#B93838'}} >{inactive_reason}</p>
            </div>
          }
          <div className="firm-row-likes-wrapper">
            <div className={["firm-row-likes",b.dFlex,b.px2,b.justifyContentCenter,].join(" ")}>
              <div className={["stars-container", b.mr2,].join(" ")}>
                <div className="star-box" style={{ width: ` ${rating}% ` }}>
                  <Icon size={20} icon="Star" className={b.mr1} />
                  <Icon size={20} icon="Star" className={b.mr1} />
                  <Icon size={20} icon="Star" className={b.mr1} />
                  <Icon size={20} icon="Star" className={b.mr1} />
                  <Icon size={20} icon="Star" />
                </div>
                <div className="empty-star-box">
                  <Icon size={20} icon="Empty-star" className={b.mr1} />
                  <Icon size={20} icon="Empty-star" className={b.mr1} />
                  <Icon size={20} icon="Empty-star" className={b.mr1} />
                  <Icon size={20} icon="Empty-star" className={b.mr1} />
                  <Icon size={20} icon="Empty-star" />
                </div>
              </div>
              <p className={[b.mb0, "blackToWhite",'font-size-13',b.textCenter,b.pt1].join(" ")}>
                {(rating / 20).toFixed(1)}
              </p>
            </div>
          </div>
        </div>
      <div className={["blackToWhite",b.mx0,b.px3,"font-size-13",b.mt3].join(" ")}>
        Poznaj ofertę {name}. Sprawdź dostępne produkty i skorzystaj już teraz!
      </div>
      <div className={["bank-icons-wrapper",b.mt3].join(' ')}>
        <div className={[b.row, b.mx0, b.px2,b.positionRelative,].join(" ")}>
          {credits && (
            credits.map(({ type, credit_type, ...credit }, i) => (
              <div className={[isOne ? b.colLg12 : b.colLg6, b.mb2, b.px1,'bank-list'].join(" ")} key={i}>
                <Icon size={40} icon={creditValues[type].icon} className={[b.dBlock, b.mxAuto, b.mb2].join(" ")}>
                  <Link to={resolveProductUrl({ bank, ...credit })} className={[b.card,b.py2,b.textCenter,b.textUppercase,'font-size-10',].join(" ")}>
                    <Translate tKey={creditValues[type].text} /> <br/>
                    {name}
                  </Link>
                </Icon>
              </div>
            ))
          )}
          {accounts && (
            accounts.map(({ type, account_type, ...account }, i) => (
              <div className={[isOne ? b.colLg12 : b.colLg6, b.mb2, b.px1,'bank-list'].join(" ")} key={i}>
                <Icon size={40} icon={accountValues[type].icon} className={[b.dBlock, b.mxAuto, b.mb2].join(" ")}>
                  <Link to={resolveProductUrl({ bank, ...account })} className={[b.card,b.py2,b.textCenter,b.textUppercase,'font-size-10',].join(" ")}>
                    <Translate tKey={accountValues[type].text} /> <br/>
                    {name}
                  </Link>
                </Icon>
              </div>
            ))
          )}
        </div>
        {countProduct > 4 ? (
        <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.px3].join(' ')}>
          <button className={["btn-hidden",'blueToWhite','iconToWhite'].join(' ')} onClick={() => setIsOpen(isOpen === i ? -1 : i)} role="button" tabIndex={0} onKeyDown={setIsOpen}>
            {isOpen === i ? (
              <p className={b.my0}>
                <Icon size={25} icon="Angle-up" className={[b.mr1, "icon-blog"].join(" ")} key={`${i}-up`}/>
                Zwiń produkty
              </p>

            ) : (
            <p className={b.mb0}>
              <Icon size={25} icon="Angle-down" className={[b.mr1, "icon-blog"].join(" ")} key={`${i}-down`}/>
              Pozostałe produkty
            </p>)}
          </button>
        </div>
        ) : ('')}
      </div>
      </div>
      <div>
      <div className={[b.dFlex, b.alignItemsEnd, b.col, b.w100, b.px3].join(" ")}>
        <Link to={resolveProductUrl(bank)} className={[b.w100, b.dBlock].join(' ')}>
          <span className={[b.btn,"btn-info",b.mt3,"btn-normal"].join(" ")}>
            <Icon color="#ffffff" size={20} icon="Info" className={[b.mr1, "icon-blog"].join(" ")}/>
            <Translate tKey="Info about"/> {name}
          </span>
        </Link>
      </div>
      {bankPromoUrl &&
        <div className={[b.dFlex, b.alignItemsEnd, b.col, b.w100, b.px3].join(" ")}>
          <Link to={bankPromoUrl} className={[b.w100, b.dBlock].join(' ')}>
            <span className={[b.btn,'btn-light',b.mt1,"btn-normal"].join(" ")}>
            <Icon size={20} icon="Bulb" className={[b.mr1, "icon-blog"].join(" ")}/>
              Aktualne promocje {name}
            </span>
          </Link>
        </div>
      }
      
      </div>
    </div>
  </div>
  )
}


BankListItem.propsTypes = {
    credits: PropsType.object,
    account: PropsType.object,
    card: PropsType.object,
    name: PropsType.string,
    mysqlImage: PropsType.object,
    i: PropsType.object,
    bank: PropsType.object,
    rating: PropsType.object,
    bankPromoUrl: PropsType.object,
  };

export default BankListItem