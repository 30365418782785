import React, { useState, useEffect } from 'react';
import * as b from "../components/bootstrap.module.css";

const getter = (item, keys) => 
  item && keys.length > 0 
    ? getter(item[keys.shift()], keys)
    : item;

const sortCallback = (properties, isDescendingOrder) => (itemA, itemB) => {
  const propA = getter(itemA, [...properties]);
  const propB = getter(itemB, [...properties]);

  if (propA === '' || propA === null || propA === undefined) {
    return 1;
  }

  if (propB === '' || propB === null || propB === undefined) {
    return -1;
  }

  const numberA = typeof propA === 'number' 
    ? propA 
    : Math.max(...propA.split('/').map(el => Number(el.trim())));

  const numberB = typeof propB === 'number' 
    ? propB 
    : Math.max(...propB.split('/').map(el => Number(el.trim())));

  return isDescendingOrder  
    ? numberB - numberA
    : numberA - numberB;
}

const getOptionValues = (cluster) => {
  if (cluster === 'firms_1') {
    return [
      { value: 'default', name: 'Polecane oferty', },
      { value: 'costs.free', name: 'Darmowa pożyczka', },
      { value: 'costs.cost', name: 'Najtańsza oferta', },
      { value: 'rating', name: 'Najlepsze opinie', },
    ];
  }

  if (cluster === 'firms_2') {
    return [
      { value: 'default', name: 'Polecane oferty', },
      { value: 'costs.cost', name: 'Najtańsza oferta', },
      { value: 'rating', name: 'Najlepsze opinie', },
    ];
  }

  if (cluster.startsWith('accounts')) {
    return [
      { value: 'default', name: 'Polecane oferty', },
      { value: 'account_fees', name: 'Najniższe opłaty za prowadzenie konta', },
      { value: 'payment_bank', name: 'Najniższe opłaty za bankomat', },
      { value: 'card_fees', name: 'Najniższe opłaty za kartę', },
    ]; 
  }

  if (cluster.startsWith('credits')) {
    return [
      { value: 'default', name: 'Polecane oferty', },
      { value: 'calculations.apr', name: 'Najniższe RRSO', },
      { value: 'percent', name: 'Najniższe oprocentowanie', },
      { value: 'calculations.rate', name: 'Najniższa rata', },
      { value: 'calculations.cost', name: 'Najniższa kwota całkowita do spłaty', },
    ];
  }

  return null;
}

const ProductSortOptions = ({ setSortAlgorithm, cluster }) => {
  const [property, setProperty] = useState('default');
  const setPropertyFromInput = (e) => setProperty(e.target.value);
  const options = getOptionValues(cluster);

  useEffect(() => {
    const callback = property === 'default' 
      ? null
      : sortCallback(property.split('.'), ['costs.free', 'rating'].includes(property));

    setSortAlgorithm(() => callback);
  }, [property]);

  if (!options) {
    return null;
  }

  return (
    <div className={[b.dFlex,b.textCenter,b.alignItemsCenter].join(' ')}>
      Sortuj:
      <select value={property} onChange={setPropertyFromInput} className={['select',b.ml3].join(' ')}>
        {options.map(({ name, value }) => (
          <option key={value} value={value}>{name}</option>
        ))}
      </select>
    </div>
  );
};

export default ProductSortOptions;