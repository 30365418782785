import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Translate from "./translate";
import * as b from "../components/bootstrap.module.css";
import scrollTo from "gatsby-plugin-smoothscroll";
import { SCROLL_CLASS, DIRECTION_DOWN_CLASS, DIRECTION_UP_CLASS, } from "../utils/classes";
import useMedia from "../hooks/useMedia";
import Icon from "../components/icon.tsx";

let idTimeout = null;

const TableOfContents = ({ selector, isOnlyMobile, intermediary, children }) => {

  const isDesktopResolusion = useMedia(["(max-width: 1200px)"], [true], false);
  const isSmallResolution = useMedia(["(max-width: 328px"], [true], false);
  const isPhoneResolution = useMedia(["(max-width: 628px"], [true], false);
  const [isOpen, setIsOpen] = useState(false);
  const [listItems, setListItems] = useState([]);
  const [tocClasses, setTocClasses] = useState(["toc", b.card].join(" "));
  const tocRef = useRef(null);

  useEffect(() => {
    const allH2 = document.querySelectorAll(selector.slice(0, -4));
    allH2.forEach((h2, i)=> {
      if(h2.textContent.trim().length !== 0) {
        h2.setAttribute('id', `section-title-${i+1}`);
      }
    })
  }, [])


  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('id');
        // if (entry.isIntersecting) {
        //   document.querySelector(`.toc ul li button[data-id="${id}"]`).classList.add('active-product-button');
        // } else {
        //   document.querySelector(`toc ul.dark-theme-listing li button[data-id="${id}"]`).classList.remove('active-product-button');
        // }
      });
    },{
      rootMargin: '-300px 0px',
      threshold: [0.01, 0.5, 1],
    }
  );
    document.querySelectorAll('h2[id]').forEach((h2) => {
      observer.observe(h2);
    });
  }, [])

  const scroll = (id) => {

    clearTimeout(idTimeout);
    setIsOpen(false);
    document.body.classList.remove(DIRECTION_UP_CLASS);
    document.body.classList.add(SCROLL_CLASS, DIRECTION_DOWN_CLASS);
    scrollTo(`#${id}`);
    idTimeout = setTimeout(
      () => document.body.classList.remove(SCROLL_CLASS),
      2000
    );
  };

  useEffect(() => {
    if (!selector.length) {
      return;
    }
    const allH2 = document.querySelectorAll(selector);
    setListItems(
      Object.values(allH2).map(({ textContent, id }, i) => (
        <li key={i} >
          <button onClick={() => scroll(id)} className={["btn-hidden",'blackToWhite'].join(' ')} data-id={id}>
            {textContent}
          </button>
        </li>
      ))
    );
  }, [selector]);
  
  useEffect(() => {
    if (listItems.length > 1) {
      document.body.classList.add('is-toc');
      document.body.classList.toggle('is-open-toc', isOpen);
    }
    
    return () => {
      document.body.classList.remove('is-toc');
      document.body.classList.remove('is-open-toc');
    }
    }, [isOpen, listItems]);

  useEffect(() => {
    if (
      tocRef.current &&
      window.innerHeight > tocRef.current.offsetHeight + 100
    ) {
      setTocClasses(["product-toc", b.card].join(" "));
    } else {
      setTocClasses(["product-toc", b.card].join(" "));
    }
  }, [isDesktopResolusion, tocRef]);

  if (listItems.length <= 1) {
    return null;
  };

  return !isDesktopResolusion && !isOnlyMobile ? (
    <div className={intermediary ? b.colXl12 + b.px0 + ' toc-wrapper sticky ' : b.colXl4}>
      <div className={!intermediary ? ' toc-wrapper sticky ' + b.mb4 : ''}>
        <div className={["toc", b.card, "table-of-contents",b.p3,b.mt3].join(' ')}>
          <h4 className="toc-title">Spis treści</h4>
          <ul ref={tocRef} className={['dark-theme-listing','blackToWhite'].join(' ')}>{listItems}</ul>
        </div>
        {children}
        <div className={[b.my3].join(' ')}>
          <div className={["widget-application",b.dFlex,b.justifyContentBetween,b.p2].join(' ')}>
            <div className={b.col8}>
              <small>Potrzebujesz pieniędzy na już?</small>
              <p className={[b.my0,'blueToWhite'].join(' ')}>Złóż szybki wniosek!</p>
            </div>
            <a href="https://out.ofin.pl/wniosek/kredyt" target='_blank' className={[b.btn,b.mx2,b.mt1,'btn-success',b.textWhite,b.fontWeightNormal].join(' ')} >
                Wnioskuj
            </a>
          </div>
        </div>
      </div>
    </div>

) : (
  <div className={["mobile-toc", b.dXlNone, isOpen ? "is-open" : ""].join(" ")}>
    <button className={["mobile-toc-angles", "btn-hidden"].join(" ")} onClick={() => setIsOpen(!isOpen)}>
      <span className={[b.mr2,'blackToWhite'].join(' ')}>{isOpen ? "Zwiń" : "Spis treści"}</span>
      {isOpen ? (
        <Icon key="1" size={40} icon="Angle-down" className='iconToWhite'/>
        ) : (
        <Icon key="2" size={40} icon="Angle-up" className='iconToWhite'/>
      )}
    </button>

    <div className={["mobile-toc-container", b.container].join(" ")}>
      <ul className={[b.my0, b.mb3,'dark-theme-listing'].join(" ")}>{listItems}</ul>
    </div>

    {children}
    <div className={[b.my3,b.colXl4,].join(' ')}>
      <div className={["widget-application",b.dFlex,b.justifyContentBetween].join(' ')}>
        <div>
          <small>Potrzebujesz pieniędzy na już?</small>
          <p className={b.my0}>Złóż szybki wniosek!</p>
        </div>
        <a href="https://out.ofin.pl/wniosek/kredyt" target='_blank' className={[b.btn,b.mt0,'btn-success',b.textWhite,b.fontWeightNormal, isSmallResolution ? 'font-size-13' : '',isPhoneResolution ? b.col4 : b.col3 ].join(' ')} >
          Wnioskuj
        </a>
      </div>
    </div>
  </div>
  
);
};


TableOfContents.defaultProps = {
  intermediary: false,
}

TableOfContents.propTypes = {
  selector: PropTypes.string.isRequired,
  isOnlyMobile: PropTypes.bool,
  intermediary: PropTypes.bool,
};

export default TableOfContents;