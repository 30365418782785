import React, { Fragment, useState } from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import Thumbnail from "../components/thumbnail";
import BlogContent from "../components/blog-content";
import Posts from "../components/posts";
import Comments from "../components/comments";
import CommentsForm from "../components/comments-form";
import TableOfContents from "../components/table-of-contents";
import Translate from "../components/translate"
import Breadcrumbs from "../components/breadcrumbs";
import useMedia from "../hooks/useMedia";
import RankingShortcode from '../components/shortcodes/ranking-shortcode';
import CalculatorShortcode from '../components/shortcodes/calculator-shortcode';
import Layout from "../components/layout";
import Icon from "../components/icon.tsx";
import Faq from "../components/faq";

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";
import resolveUrl from "../utils/urls/resolve-url";

export const Head = ({...props}) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/blog/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const Blog = ({ 
  data: { mysqlBlog, allMysqlBlog, allMysqlFaq, allMysqlComments }, 
  pageContext: { breadcrumbs, mysqlId },
  location: { pathname },
}) => {
  const {
    title,
    mysqlImages,
    image_canvas,
    image_description,
    intro,
    published_date,
    content,
    author,
    video,
    ranking,
    related_rankings,
  } = mysqlBlog;
  const isMobileResolution = useMedia(['(max-width: 991px)'], [true], false);

  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const [isFaq, setIsFaq] = useState(false);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)


  return (
    <Layout pathname={pathname}>
      <article className="blog-page">
        {isMobileResolution &&(
          <div className={['breadcrumbs-mobile-2', b.ptLg5, "scroll-inner"].join(' ')}>
            <Breadcrumbs items={breadcrumbs} />
          </div>
        )}
        <header className="blog-header">
          {video ? (
            <div className={[b.embedResponsive, b.embedResponsive21by9].join(" ")}>
              <iframe
                src={`https://www.youtube-nocookie.com/embed/${video}?rel=0&mute=1&autoplay=1`}
                className={b.embedResponsiveItem}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="video"
              ></iframe>
            </div>
          ) : (<>
            {mysqlImages && <div className={[b.dFlex, b.justifyContentCenter].join(' ')} style={{ background: '#273EAC' }}>
              <Thumbnail
                image={image_canvas ? mysqlImages[1] : mysqlImages[0]}
                className={image_canvas ? "blog-img-canvas" : "blog-img"}
                alt={image_description || ""}
              />
            </div>}
          </>)}
          <div className="blog-header-text">
              {!isMobileResolution &&<div className={[b.mb3, b.pt4].join(' ')}><Breadcrumbs items={breadcrumbs} textWhite/></div>}
            <div className={[b.container, isMobileResolution ? b.py4 : b.pb4].join(" ")}>
              <h1 className={["big-title", b.mb3,b.textWhite].join(" ")}>{title}</h1>
              <p className="blog-lead">{intro}</p>
              {author && 
                <div className={["blog-author-text", "blog-header"].join(" ")}>
                  <div className={["blog-author", b.dFlex, b.alignItemsCenter].join(" ")}>
                    <Link to={resolveUrl('redakcja', author.name)} className={["blog-author-text", b.textWhite, b.dFlex, b.alignItemsCenter].join(' ')}>
                      <Thumbnail image={author.mysqlImage} className="blog-author-img" alt={author.name}/>
                      <span className={["blog-author-text", b.ml3].join(" ")}>
                        {author.name}
                      </span>
                    </Link>
                    <span className="blog-author-text">
                      , {published_date}
                    </span>
                  </div>
                </div>
              }
            </div>
          </div>
        </header>
        <div className={b.container}>
          {content && (
            <CalculatorShortcode value={content}>
              {({ startHalf, calculator, endHalf }) => (<>
                {calculator && <div className="calculator-wrapper">{calculator}</div>}
                <RankingShortcode value={endHalf || startHalf} type={{ big: ranking, small: related_rankings?.split(',').filter(Boolean) }}>
                   {(fragments) => {
                     const bigRanking = fragments[0].isRankingWidget ? fragments.shift().ranking : null;
       
                     return (
                       <>
                         <div className={["blog-content", b.row, b.mtLg3,'blueToGray', 'dark-li'].join(" ")} id="content">
                           {bigRanking && <div className={`${b.colXl12} dark-theme-listing`}>
                             {bigRanking}
                           </div>}
       
                           <div className={`${b.colXl8} dark-theme- ${b.mb3}`}>
                             {fragments?.map(({ slice, ranking }, i) => (<Fragment key={i}>
                               {slice && <BlogContent content={slice} className="article" />}
                               {ranking}
                             </Fragment>))}
       
                             {allMysqlFaq?.nodes.length > 0 && (
                               <>
                                 <div className="post-content">
                                   <h2>
                                     <Translate tKey="Often asked questions"/>
                                   </h2>
                                   
                                   {allMysqlFaq.nodes.map(({ title, text }, i) => <Faq title={title} text={text} key={i} />)}
                               
                                   <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                                     <button className={[b.btn,"btn-light","btn-cta","button-256",].join(" ")}  onClick={() => { toggleForm();toggleFaq(true); }}>
                                       <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")}/>
                                       <Translate tKey="Ask your own question"/>
                                     </button>
                                   </div>
                                 </div>
                               </>
                             )}
                           </div>
                           <div className={[b.colLg4,b.ptLg5,b.pbLg3].join(' ')}>
                              <TableOfContents selector="#content .post-content h2[id]" intermediary />
                           </div>
                         </div>
                       </>
                     );
                   }}
                 </RankingShortcode>
              </>)}
            </CalculatorShortcode>
          )}
                      
          <Comments items={allMysqlComments.nodes} title="standard" descHeader={`Komentarze`} formID={mysqlId} commentType="entry"/>
          <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false);toggleFaq(false); }} formID={mysqlId} commentType="entry" faq={isFaq} />

          {allMysqlBlog.nodes.length > 0 && <aside className={[b.mb5, b.pbLg5].join(" ")}>
            <h2 className={["title", b.dBlock, b.mb4,b.mt3].join(' ')}>Podobne treści {mysqlBlog.title}</h2>
            <Posts items={allMysqlBlog.nodes} />
          </aside>}
        </div>
      </article>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query Blog($mysqlId: Int!, $withoutUrls: [String], $categories: [Int]) {
    mysqlBlog(status: { eq: "publish" }, mysqlId: { eq: $mysqlId }) {
      title
      description
      intro
      image_description
      url
      content
      published_date
      video
      ranking
      image_canvas
      related_rankings
      mysqlImages {
        childImageSharp {
          gatsbyImageData(width: 2000)
        }
      }
      author {
        name
        mysqlImage {
          childImageSharp {
            gatsbyImageData(width: 70, height: 70)
          }
        }
      }
    }
    allMysqlBlog: allMysqlBlog(filter: {url: {nin: $withoutUrls}, mysqlId: {ne: $mysqlId}, blogTerms:{elemMatch:{type: {eq: "tag"}, mysqlId: {in: $categories}}}, description: {ne: null}, status: {eq: "publish"}}) {
      nodes {
        ...PostData
      }
    }
    allMysqlFaq(
      filter: { item: { eq: $mysqlId }, type: { eq: "article" }, answer: { ne: null } },
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        title: question
        text: answer
        priority
      }
    }
    allMysqlComments(filter: {type: {eq: "entry"}, item: {eq: $mysqlId}}, sort: {order: DESC, fields: up}) {
      nodes {
        ...CommentData 
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "article" }}) {
      nodes {
        ...SeoData
      }
    }
  }

`;
