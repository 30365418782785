import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as b from "../components/bootstrap.module.css"
import Icon from "../components/icon.tsx"
import Translate from './translate'

const PRODUCT_REQUIREMENTS = {
  id: {
    text: <Translate tKey="ID card"/>,
    icon: <Icon size={40} icon="Id"/>
  },
  mobile: {
    text: <Translate tKey="Mobile phone"/>,
    icon: <Icon size={40} icon="Cell-phone"/>
  },
  account: {
    text: <Translate tKey="Bank account"/>,
    icon: <Icon size={40} icon="Piggy-bank"/>
  },
  pesel: {
    text: <Translate tKey="Person Id"/>,
    icon: <Icon size={40} icon="Id"/>
  },
  home: {
    text: <Translate tKey="Permanent place of residence"/>,
    icon: <Icon size={40} icon="Mortgage"/> 
  },
  experience: {
    text: <Translate tKey="Company from min"/>,
    icon: <Icon size={40} icon="Person"/>
  },
  income: {
    text: <Translate tKey="Income statement"/>,
    icon: <Icon size={40} icon="Coins"/>
  },
  debetors_base: {
    text: <Translate tKey="No register in the debtors database"/>,
    icon: <Icon size={40} icon="Junior-account"/> 
  },
}

const RequirementsList = ({ children, items, firmType, ageMin, ageMax }) => {
  const [ageText, setAgeText] = useState('')

  useEffect(() => {
    if (firmType !== 16) {
      if (ageMax > 0 && ageMax !== ageMin) {
        setAgeText("Age from %1$s to %2$s")
      } else if (ageMin > 0) {
        setAgeText("Age from %1$s")
      }
    } else if (ageMin > 0) {
      setAgeText("Company from %1$s")
    } else if (ageMin === 0) {
      setAgeText("Company from 1 day")
    }
  }, [firmType, ageMin, ageMax])

  return (
    <section>
      {children}
      <div className={[b.row, b.textCenter, b.mt3].join(' ')}>
        {ageText.length > 0 &&(
         <div className={[b.colSm6, b.colLg3,b.mt3].join(' ')}>
          <div>
            <Icon size={40} icon="Person"/> 
            <span className={[b.dBlock, b.mt3].join(' ')}>
              <Translate tKey={ageText}>
                {(value) => (
                  <span className={[b.dBlock, b.mt3].join(' ')}>
                    {value.replace('%1$s', ageMin).replace('%2$s', ageMax)}
                  </span>
                )}
              </Translate>
            </span>
          </div>
        </div>)}
        {items ? items.split(' ').map((req, i) =>
          PRODUCT_REQUIREMENTS[req] ? (
            <div className={[b.colSm6, b.colLg3, b.mt3].join(' ')} key={i}>
              {PRODUCT_REQUIREMENTS[req].icon}
              <span className={[b.dBlock, b.mt3].join(' ')}>{PRODUCT_REQUIREMENTS[req].text}</span>
            </div>
          ) : null
        ): null}
      </div>
    </section>
  )
}

RequirementsList.propTypes = {
  items: PropTypes.string.isRequired,
  firmType: PropTypes.number,
  ageMin: PropTypes.number,
  ageMax: PropTypes.number,
}

export default RequirementsList;