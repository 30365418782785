import React, { Children, useState } from 'react'
import * as b from './bootstrap.module.css'
import Icon from "../components/icon.tsx"
import Translate from './translate'

const Grid = ({ children }) => {
  const [grid, setGrid] = useState('column');

  return children({ grid, setGrid });
}

const GridSettings = ({ children, grid, setGrid }) => {
  const isMultipleColumns = Children.count(children) > 0;

  return (
    <div className={['grid', b.dFlex, isMultipleColumns ? b.justifyContentLgBetween : b.justifyContentLgEnd, b.alignItemsCenter, b.mb3].join(' ')}>
      {isMultipleColumns && <div>
        {children}
      </div>}
      
      <div className={[b.dNone, b.dLgBlock].join(' ')}>
        <span className={['grid-text', b.mr3].join(' ')}><Translate tKey="Change view" /></span>

        <button onClick={() => setGrid('row')} className={['settings-button', grid === 'row' ? 'settings-button-active' : '', b.mr2].join(' ')}>
          <Icon size={25} icon="Columne-list" className={"icon-padding"}/> 
        </button>

        <button onClick={() => setGrid('column')} className={['settings-button', grid === 'column' ? 'settings-button-active' : ''].join(' ')}>
          <Icon size={25} icon="Columne-mesh" className={"icon-padding"}/> 
        </button>
      </div>
    </div>
  );
}

export default Grid;
export { GridSettings };