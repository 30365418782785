import React from "react";

import ElixirCalculator from '../forms/calculators/elixir-calculator';
import RRSOCalculator from "../forms/calculators/rrso-calculator";
import CarCalculator from "../forms/calculators/credits/car-calculator";
import ConsolidationCalculator from "../forms/calculators/credits/consolidation-calculator";
import CreditworthinessCalculator from '../forms/calculators/credits/creditworthiness-calculator';
import MortgageCalculator from "../forms/calculators/credits/mortgage-calculator";
import CashLoanCalculator from "../forms/calculators/credits/cash-loan-calculator";
import AccountProfitCalculator from "../forms/calculators/accounts/account-profit-calculator";
import DepositCalculator from "../forms/calculators/accounts/deposit-calculator";
import CompoundInterestCalculator from "../forms/calculators/accounts/compound-interest-calculator";

const tags = {
  "elixir": ElixirCalculator, // Kalkulator sesji elixir
  "calculator-rrso": RRSOCalculator, // Kalkulator RRSO
  "calculator-credit-car": CarCalculator,  // Kalkulator kredytu samochodowego
  "credit_consolidation": ConsolidationCalculator, // Kalkulator kredytu konsolidacyjnego
  "cashworthiness": CreditworthinessCalculator, // Kalkulator zdolności gotówkowej
  "calculator-mortgage": MortgageCalculator, // Kalkulator kredytu hipotecznego
  "calculator-cash-loan": CashLoanCalculator, // Kalkulator kredytu gotówkowego
  "calculatorinterest": AccountProfitCalculator, // Kalkulator zysku z konta oszczędnościowego
  "calculatorinvestment": DepositCalculator, // Kalkulator zysku z lokaty
  "calculator-compound-interest": CompoundInterestCalculator, // Kalkulator procentu składanego
};

const CalculatorShortcode = ({ value, children }) => {
  for (const key in tags) {
    const index = value.indexOf(`[${key}]`);
    
    if (index >= 0) {
      const Calculator = tags[key];

      return children({
        startHalf: value.slice(0, index),
        calculator: <Calculator />,
        endHalf: value.slice(index)
          .replace(`[${key}]`, '')
          .replace(`[/${key}]`, '')
          .replace(/^<\/p>/, ''),
      });
    }
  }

  return children({
    startHalf: value
  });
};

export default CalculatorShortcode;
