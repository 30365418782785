import React from "react"
import { Link } from "gatsby";
import * as b from "../components/bootstrap.module.css"
import Icon from "../components/icon.tsx"
import RankingWidget from '../components/ranking-widget';
import Layout from "../components/layout";

import BaseHead from '../components/base-head';
export const Head = ({ ...props }) => (
  <BaseHead 
    {...props}
    title="Nie ma takiej strony - Błąd 404"
  />
);

const NotFoundPage = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <section className={["page-not-found"].join(' ')}>
      <div className='intro404'>
        <div className={[b.container].join(' ')}>
          <div className={[b.row,b.pyLg5,b.justifyContentBetween].join(' ')}>
            <div className={[b.pbLg5,b.ptLg3,b.colLg6,b.orderLg2].join(' ')}>
              <img src="/assets/404.png" alt="404" width="673" height="387" />
            </div>
            <div className={[ b.pyLg5,b.colLg6,'page-not-found-plLg','blackToWhite','iconToWhite' ].join(' ')}>
              <h1 className={["big-title",b.ptLg5,'blackToWhite',b.textUppercase].join(' ')}>Nie możemy tego znaleźć, przepraszamy</h1>
              <p className={[b.mb4,'page-not-found-p',b.mt3].join(' ')}>Może zainteresują Cię inne nasze treści:</p>
              <Link to='/' className={['page-not-found-button','blackToWhite'].join(' ')}><Icon color="#2B272F" size={35} icon="Angle-down" className={["firm-icons",b.mr1].join(' ')}/>Przejdź do strony głównej</Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <RankingWidget types="all" theme="PageNotFound"/>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
