import { useMemo } from "react";

const useDescription = (descriptionsArray) => {
  const descriptionsObject = useMemo(() => {
    const newDescriptions = descriptionsArray.reduce((prev, current) => {
      return {
        ...prev,
        [current.name]: {
          header: current.header,
          description: current.description,
        }
      }
    }, {});

    return newDescriptions;
  }, [descriptionsArray]);

  return descriptionsObject;
}

export default useDescription;