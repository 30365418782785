/* eslint-disable jsx-a11y/label-has-for  */
/* eslint-disable jsx-a11y/click-events-have-key-events  */
/* eslint-disable jsx-a11y/no-static-element-interactions  */

import React, { useState, useRef, useEffect } from "react";
import * as b from "./bootstrap.module.css";
import Icon from "../components/icon.tsx";
import useConfig from "../hooks/useConfig";
import PropTypes from "prop-types";
import Translate from "./translate";

const convertNumberValue = (value, { min, max, modulo }) => {
  const newValue = Number.parseInt(value, 10);

  if (newValue < min || !Number.isSafeInteger(newValue)) {
    return min;
  } else if (newValue > max) {
    return max;
  } else if (modulo) {
    return newValue - (newValue % modulo);
  }

  return newValue;
};

const btnLabels = {
  firm: "Znajdź najlepszą pożyczkę",
  firm1: "Znajdź najlepszą chwilówkę",
  credits: "Znajdź najlepszy kredyt",
  accounts: "Znajdź najlepsze konto",
  cards: "Znajdź najlepszą kartę",
};

const ProductInputs = ({settings,onChangeInput,productName,type,amount_min,amount_max,time_min,time_max, firm}) => {
  const getConfig = useConfig();

  const amountRef = useRef();
  const timeRef = useRef();
  const timeout = useRef();

  const [amount, setAmount] = useState(settings?.input?.amount || 500);
  const [time, setTime] = useState(settings?.input?.time || 24);

  const [clicked, setClicked] = useState("");

  const changeAmount = ({ value = null, update = true } = {}) => {
    const newAmount = convertNumberValue(value ?? amount, {
      min: settings?.input?.amount_min || amount_min,
      max: settings?.input?.amount_max || amount_max,
      modulo: 100,
    });

    if (!update) {
      return newAmount;
    }

    setAmount(newAmount);
  };

  const changeTime = ({ value = null, update = true } = {}) => {
    const newTime = convertNumberValue(value ?? time, {
      min: settings?.input?.time_min || time_min,
      max: settings?.input?.time_max || time_max,
    });

    if (!update) {
      return newTime;
    }

    setTime(newTime);
  };

  const changeClicked = (name) => {
    setClicked(name);

    if (name === "amount") {
      amountRef.current.focus();
    } else if (name === "time") {
      timeRef.current.focus();
    }
  };

  useEffect(() => {
    if (clicked || settings?.input?.amount === amount && settings?.input?.time === time) {
      return;
    }

    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      onChangeInput({
        ...settings,
        filters: { 
          ...settings?.filters,
          all: {
            ...settings?.filters?.all,
            withoutCalculator: false, 
          }, 
        },
        input: {
          ...settings?.input,
          amount: changeAmount({ update: false }),
          time: changeTime({ update: false }),
        },
      });
    }, 600);
  }, [amount, time, clicked, settings, onChangeInput]);

  if (type === "credit") {
    return (
      <div className={`${b.row} product-inputs`} data-wniosker-amount={amount} data-wniosker-period={time}>
        <div className={`${b.colLg6}`}>
          <label htmlFor="amount" className="blueToWhite tp-name">
            {firm ? <Translate tKey="Loan amount"/>  : <Translate tKey="How much do you need?"/>}
          </label>

          <div className={`${b.inputGroup} ${b.inputGroupLg}`}>
            <div className={b.inputGroupPrepend}>
              <button onClick={() => changeAmount({ value: amount - 100 })} className={`btn-white ${b.btn} ${b.btnSecondary}`}>
                <Icon color="#0D2181" size={20} icon="Minus-sign" />
              </button>
            </div>

            <input ref={amountRef} onChange={(e) => setAmount(e.target.value)} onFocus={() => changeClicked("amount")} onBlur={() => {changeClicked("");changeAmount();}} value={amount} id="amount" className={`form-control ${b.formControl} ${b.textCenter} comparison-input blackToWhite`}/>
            <div onClick={() => changeClicked("amount")} className={["input-mask " + b.dBlock,"blueToWhite" + (clicked === "amount" ? b.dNone : ""),].join(" ")}>
              {amount} <Translate tKey="cu" />
            </div>

            <div className={b.inputGroupAppend}>
              <button onClick={() => changeAmount({ value: amount + 100 })} className={`btn-white ${b.btn} ${b.btnSecondary}`}>
                <Icon color="#0D2181" size={20} icon="Plus-sign" />
              </button>
            </div>
          </div>
        </div>

        <div className={`${b.colLg6}`}>
          <label htmlFor="time" className="blueToWhite tp-name">
            {firm ? <Translate tKey="Repayment period">{(text) => text + ' pożyczki' }</Translate> : <Translate tKey="For how long do you need?" />}
          </label>

          <div className={`${b.inputGroup} ${b.inputGroupLg}`}>
            <div className={b.inputGroupPrepend}>
              <button onClick={() => changeTime({ value: time - 1 })} className={`btn-white ${b.btn} ${b.btnSecondary}`}>
                <Icon color="#0D2181" size={20} icon="Minus-sign" />
              </button>
            </div>

            <input ref={timeRef} onChange={(e) => setTime(e.target.value)} onFocus={() => changeClicked("time")}
              onBlur={() => {changeClicked("");changeTime();}} value={time} id="time" className={`form-control ${b.formControl} ${b.textCenter} comparison-input blackToWhite`}/>
            <div onClick={() => changeClicked("time")} className={["input-mask ","blueToWhite" + (clicked === "time" ? b.dNone : ""),].join(" ")}>
              {time} {settings?.subtypes?.firm === "1" ? "dni" : "mies"}
            </div>

            <div className={b.inputGroupAppend}>
              <button onClick={() => changeTime({ value: time + 1 })} className={`btn-white ${b.btn} ${b.btnSecondary}`}>
                <Icon color="#0D2181" size={20} icon="Plus-sign" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className={`${b.row} ${b.alignItemsEnd} product-inputs`} data-wniosker-amount={amount} data-wniosker-period={settings?.subtypes?.firm === "1" ? 1 : time}>
      <div className={`${b.colLg4} ${b.mb3} ${b.mbLg0}`}>
        <label htmlFor="amount" className="blueToWhite tp-name">
          <Translate tKey="How much do you need?" />
        </label>

        <div className={`${b.inputGroup} ${b.inputGroupLg}`}>
          <div className={b.inputGroupPrepend}>
            <button onClick={() => changeAmount({ value: amount - 100 })} className={`btn-white ${b.btn} ${b.btnSecondary}`}>
              <Icon color="#0D2181" size={20} icon="Minus-sign" className="icon-width"/>
            </button>
          </div>

          <input ref={amountRef} onChange={(e) => setAmount(e.target.value)} onFocus={() => changeClicked("amount")}
            onBlur={() => {changeClicked(""); changeAmount();}} value={amount} id="amount" className={`form-control ${b.formControl} ${b.textCenter} comparison-input blackToWhite `}/>
          <div onClick={() => changeClicked("amount")} className={["input-mask " + b.dBlock,"blueToWhite" + (clicked === "amount" ? b.dNone : ""),].join(" ")}>
            {amount} <Translate tKey="cu" />
          </div>

          <div className={b.inputGroupAppend}>
            <button onClick={() => changeAmount({ value: amount + 100 })} className={`btn-white ${b.btn} ${b.btnSecondary}`}>
              <Icon color="#0D2181" size={20} icon="Plus-sign"  className="icon-width"/>
            </button>
          </div>
        </div>
      </div>

      <div className={`${b.colLg4} ${b.mb4} ${b.mbLg0}`}>
        <label htmlFor="time" className="blueToWhite tp-name">
          <Translate tKey="When you can pay?" />
        </label>

        <div className={`${b.inputGroup} ${b.inputGroupLg}`}>
          <div className={b.inputGroupPrepend}>
            <button onClick={() => changeTime({ value: time - 1 })} className={`btn-white ${b.btn} ${b.btnSecondary}`}>
              <Icon color="#0D2181" size={20} icon="Minus-sign" />
            </button>
          </div>

          <input ref={timeRef} onChange={(e) => setTime(e.target.value)} onFocus={() => changeClicked("time")} 
            onBlur={() => {changeClicked("");changeTime();}} value={time} id="time" className={`form-control ${b.formControl} ${b.textCenter} comparison-input blackToWhite `}/>
          <div onClick={() => changeClicked("time")} className={["input-mask ","blueToWhite" + (clicked === "time" ? b.dNone : ""),].join(" ")}>
            {time} {settings?.subtypes?.firm === "1" ? "dni" : "mies"}
          </div>

          <div className={b.inputGroupAppend}>
            <button onClick={() => changeTime({ value: time + 1 })} className={`btn-white ${b.btn} ${b.btnSecondary}`}>
              <Icon color="#0D2181" size={20} icon="Plus-sign" />
            </button>
          </div>
        </div>
      </div>

      <div className={`${b.colLg4}`}>
        <button className={[b.btn,b.btnBlock,b.btnLg,getConfig(`sliders_${productName}_cta_button_color`, "btn-info"),"btn-cta",b.my0,'btn-info'].join(" ")}>
          <Icon size={20} color="#fff" icon="Search" className={`icon-margin-bottom icon-width ${b.mr1}`}/>
          {getConfig(`sliders_${productName}_cta_button_text`,btnLabels[productName] || btnLabels[settings.types] || "Znajdź najlepszy produkt")}
        </button>
      </div>
    </div>
  );
};

ProductInputs.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  productName: PropTypes.string,
  amount_min: PropTypes.number,
  amount_max: PropTypes.number,
  time_min: PropTypes.number,
  time_max: PropTypes.number,
};

ProductInputs.defaultProps = {
  amount_min: 100,
  amount_max: 100000,
  time_min: 1,
  time_max: 60,
};

export default ProductInputs;
