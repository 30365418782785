import React, { useState, Suspense, lazy } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Breadcrumbs from "../components/breadcrumbs";
import Icon from "../components/icon.tsx";
import * as b from "../components/bootstrap.module.css";
import Thumbnail from "../components/thumbnail";

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";

export const Head = ({ ...props }) => {
  const name = props.data?.mysqlLocalfirms?.name?.split(' -')[0]?.trim();
  const address_city = props.data?.mysqlLocalfirms?.address_city;
  const count = (props.data?.allMysqlLocalfirms?.edges?.length || 0) + 1;

  const title = name && address_city 
    ? `${name} - ${address_city} - informacje o placówkach | ofin.pl` 
    : `${name} - informacje o placówkach | ofin.pl`;
  const description = name && count
    ? `${name} posiada w tym mieście ${count} placówek. Sprawdź adresy, godziny otwarcia i ofertę lokalnych przedstawicieli.` 
    : '';
  
  return (
    <BaseHead 
      {...props} 
      title={title} 
      description={description}
    >
      {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/localfirm/${props.pageContext.mysqlId}.png`} /> }
    </BaseHead>
  );
};

const OpenStreetMap = lazy(() => import('../components/open-street-map'));

const Localfirms = ({
  data: { mysqlLocalfirms, allMysqlLocalfirms },
  location: { pathname },
  pageContext: { breadcrumbs },
}) => {
  const {
    name,
    address_city,
    address_code,
    address_street,
    phone,
    email,
    krs,
    nip,
    regon,
    longitude,
    latitude,
    mysqlImage
  } = mysqlLocalfirms;

  const [isOpen, setIsOpen] = useState(false);
  const firmName = name.split(/[–-]\s/)[0];

  const [isActive, setIsActive] = useState(null);

  const downloadMapComponent = () => {
    setIsActive(true);
  };

  return (
    <Layout pathname={pathname}>
      <div className={["local-firms-header",'intro-products2', b.dFlex, b.justifyContentCenter,].join(" ")}>
      </div>
      <div className={[b.container,'breadcrumbs-mobile-2',b.py3,b.ptLg5,"scroll-inner"].join(' ')}>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <article className={"firm-page"}>
        <div className={["local-firm-page", "blog-content", "post-content",'local-firms-w-map',b.pb4].join(" ")}>
          <div className={b.container}>
            <div className={b.row}>
              <div className={b.colLg8}>
                <h1 className={["big-title", b.mt3,b.mtLg4, b.textWhite].join(" ")}>{firmName} - {address_city}</h1>

                <p className={['font-size-20',b.pb3,b.mb3, b.textWhite].join(' ')}>
                  {firmName} posiada w tym mieście {allMysqlLocalfirms.nodes.length + 1} placówek. W każdej z nich uzyskasz informacje o aktualnej ofercie banku i pomoc w rozwiązaniu problemów związanych z obsługą klienta. Sprawdź listę czynnych banków i znajdź najbliższą lokalizację Twojego banku!
                </p>

                <div className="map-wrapper" style={{ position: 'relative' }}>
                  {!isActive && <button 
                    style={{ 
                      position: 'absolute', 
                      top: 0, 
                      right: 0, 
                      bottom: 0, 
                      left: 0,
                      border: 0,
                      background: 'transparent',
                      fontSize: '22px',
                      cursor: 'pointer',
                    }} 
                    onClick={downloadMapComponent} 
                  >
                    Kliknij, aby załadować mapę
                  </button>}

                  {isActive && (
                    <Suspense fallback={<></>}>
                      <OpenStreetMap longitude={longitude} latitude={latitude} localfirms={allMysqlLocalfirms.nodes} />
                    </Suspense>
                  )}
                </div>

                <div className={[b.colMd,isOpen === -1 ? "firm-info-box-open" : "firm-info-box",b.mt3].join(" ")}>
                  <div className={[b.dFlex, b.justifyContentBetween].join(" ")} onClick={() => setIsOpen(isOpen === -1 ? -2 : -1)} role="button" tabIndex={0} onKeyDown={setIsOpen}>
                    <span className={['font-size-24','blackToWhite', b.py3].join(" ")} style={{ fontWeight: 500 }}>
                      {address_street}
                    </span>
                    
                    <button className={"btn-hidden"}>
                      {isOpen === -1 ? (
                        <Icon color="#21584D" size={35} icon="Angle-up" className={"firm-icons"} key="-1-up"/>
                      ) : (
                        <Icon color="#21584D" size={35} icon="Angle-down" className={"firm-icons"} key="-1-down"/>
                      )}
                    </button>
                  </div>
                  <div className={"firm-description"}>
                    <div className={["local-firm-fs",'blueToWhite'].join(' ')}>
                      <p className={b.mt0}>{address_code} {address_city}</p>
                      <p>Telefon: {phone}</p>
                      <p className={b.mt0}>Email: {email || '-'}</p>
                      <p className={b.mt0}>KRS: {krs}</p>
                      <p className={b.mt0}>NIP: {nip}</p>
                      <p className={b.mt0}>REGON: {regon}</p>
                    </div>
                  </div>
                </div>

                {allMysqlLocalfirms.nodes.map((localfirm, i) => (
                  <div key={i} className={[b.colMd, isOpen === i ? "firm-info-box-open" : "firm-info-box",b.mt3].join(" ")}>
                    <div className={[b.dFlex, b.justifyContentBetween].join(" ")} onClick={() => setIsOpen(isOpen === i ? -2 : i)} role="button" tabIndex={0} onKeyDown={setIsOpen}>
                      <span className={['font-size-24','blackToWhite', b.py3].join(" ")} style={{ fontWeight: 500 }}>
                        {localfirm.address_street}
                      </span>
                      
                      <button className={"btn-hidden"}>
                        {isOpen === i ? (
                          <Icon color="#21584D" size={35} icon="Angle-up" className={"firm-icons"} key="-1-up"/>
                        ) : (
                          <Icon color="#21584D" size={35} icon="Angle-down" className={"firm-icons"} key="-1-down"/>
                        )}
                      </button>
                    </div>
                    <div className={"firm-description"}>
                      <div className={["local-firm-fs",'blueToWhite'].join(' ')}>
                        <p className={b.mt0}>{localfirm.address_code} {localfirm.address_city}</p>
                        <p>Telefon: {localfirm.phone}</p>
                        <p className={b.mt0}>Email: {localfirm.email || '-'}</p>
                        <p className={b.mt0}>KRS: {localfirm.krs}</p>
                        <p className={b.mt0}>NIP: {localfirm.nip}</p>
                        <p className={b.mt0}>REGON: {localfirm.regon}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className={[b.colLg4, b.mt3, b.mtLg5].join(' ')}>
                {mysqlImage && (
                  <div className={"local-firm-img-box"}>
                    <div className='bank-padding-img'>
                      <Thumbnail image={mysqlImage} alt={name} />
                    </div>
                  </div>
                )}
                <div className={"local-firm-box"}>
                  <span className="font-size-24" style={{ fontWeight: 500 }}>Lista placówek</span>
                  <ul className='dark-theme-listing'>
                    <li className={['post-text',b.pt1].join(' ')}>
                      {address_street}
                    </li>
                    {allMysqlLocalfirms.nodes.map((localfirm, i) => (
                      <li className={['post-text',b.pt1].join(' ')} key={i}>
                        {localfirm.address_street}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default Localfirms;

export const query = graphql`
  query QueryLocalfirms($mysqlId: Int!) {
    mysqlLocalfirms(mysqlId: { eq: $mysqlId }) {
      name
      address_city
      address_code
      address_street
      phone
      email
      krs
      nip
      regon
      longitude
      latitude
      mysqlImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }

    allMysqlLocalfirms(filter: {parentId: {eq: $mysqlId}}) {
      nodes {
        name
        address_city
        address_code
        address_street
        phone
        email
        krs
        nip
        regon
        longitude
        latitude
      }
    }
  }
`;
