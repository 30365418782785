import React, { useState } from 'react';
import * as b from "../bootstrap.module.css";
import Icon from "../icon";

const InputNumber = ({
  suffix,
  step,
  minValue,
  value,
  maxValue,
  updateValue,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [isActive, setIsActive] = useState(false);

  const minusStep = () => {
    const number = betweenValue(minValue, value - step, maxValue, step);

    setInputValue(number);
    updateValue(number);
  };

  const addStep = () => {
    const number = betweenValue(minValue, value + step, maxValue, step);

    setInputValue(number);
    updateValue(number);
  };

  const toggleActiveStatus = () => {
    setIsActive(!isActive);
  };

  const updateInputValue = (e) => {
    const number = parseFloat(e.target.value);

    if (Number.isFinite(number) || e.target.value === '') {
      if (step < 1 && /[.,0]$/.test(e.target.value)) {
        setInputValue(e.target.value.replace(/,+/, '.'));
      } else if (number || number === 0) {
        setInputValue(number);
      } else {
        setInputValue('');
      }
    }
  }

  const saveNewValue = () => {
    toggleActiveStatus();

    const number = betweenValue(minValue, parseFloat(inputValue) || minValue, maxValue, step);

    if (Number.isFinite(number)) {
      setInputValue(number);
      updateValue(number);
    }
  };

  return (
    <div className={[b.inputGroup, b.inputGroupLg].join(" ")}>
      <div className={b.inputGroupPrepend}>
        <button className={['btn-white', b.btn, b.btnSecondary].join(" ")} onClick={minusStep}>
          <Icon color="#21584D" size={25} icon="Minus-sign" className="icon-width"/>
        </button>
      </div>
      
      <input
        {...props}
        type="text"
        className={[b.formControl,b.textCenter, 'form-control comparison-input blackToWhite'].join(' ')}
        style={{ fontWeight: 500 }}
        value={formatInputValue(inputValue, isActive, step, suffix)}
        onFocus={toggleActiveStatus}
        onChange={updateInputValue}
        onBlur={saveNewValue}
      />

      <div className={b.inputGroupPrepend}>
        <button 
          className={[b.btn, "btn-white", b.btnSecondary].join(" ")}
          style={{ position: 'relative', left: '-1px', borderTopRightRadius: '0.3rem', borderBottomRightRadius: '0.3rem' }}
          onClick={addStep}
        >
          <Icon color="#21584D" size={25} icon="Plus-sign" className="icon-width"/>
        </button>
      </div>
    </div>
  );
};

const formatInputValue = (inputValue, isActive, step, suffix) => {
  if (isActive) {
    return inputValue;
  }

  return step < 1 
    ? `${inputValue.toFixed(2)} ${suffix}`
    : `${inputValue} ${suffix}`;
}

const betweenValue = (start, value, end, step) => {
  const number = 
    step < 1 
      ? parseFloat(value.toFixed(2))
      : value - (value % step);
  
  return Math.min(Math.max(number, start), end);
}

export default InputNumber;