import React, { useContext, createContext, useMemo } from 'react'
import PropTypes from "prop-types";
import { graphql,useStaticQuery } from "gatsby";

export const TranslateContext = createContext();

export const TranslateProvider = ({ children }) => {
  const { allMysqlTranslate } = useStaticQuery(graphql`
    query Translate {
      allMysqlTranslate (filter: { lang: { eq: "pl" }}){
        nodes {
          key
          value
        }
      }
    }
  `);

  const translates = useMemo(() => {
    const newTranslate = allMysqlTranslate.nodes.reduce((prev, current) => {
      return {
        ...prev,
        [current.key]: current.value,
      }
    }, {});

    return newTranslate;
  }, [allMysqlTranslate.nodes]);

  return (
    <TranslateContext.Provider value={{ translates }}>
      {children}
    </TranslateContext.Provider>
  )
} 

const Translate = ({tKey, children}) => {
  const { translates } = useContext(TranslateContext);
  
  if (children) {
    const values = Array.isArray(tKey)
      ? tKey.reduce((prev, curr) => ({ ...prev, [curr]: translates[curr] }), {})
      : translates[tKey];

		return children(values);
	}
  
  return (
    <>
      {translates[tKey]}
    </>
  )
}

Translate.propTypes = {
  tKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default Translate
