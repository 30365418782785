import React from 'react';
import { graphql, Link } from 'gatsby';
import * as b from "../components/bootstrap.module.css"
import BlogContent from '../components/blog-content';
import TableOfContents from '../components/table-of-contents';
import Breadcrumbs from "../components/breadcrumbs"
import Icon from "../components/icon"
import Layout from "../components/layout";
import Posts from '../components/posts';
import resolveUrl from '../utils/urls/resolve-url';
import useMedia from '../hooks/useMedia';

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const NonBankLoans = ({ 
  data: { allMysqlTerms, allMysqlBlog, mysqlTerms },
  pageContext: { breadcrumbs },
  location: { pathname },
}) => {

  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);

  return (
    <Layout pathname={pathname}>
      <div>
        <section className={`intro-products2 ${b.py4} ${b.pyMd5} `}>
        {isMobileResolution ? (
          <div className={['breadcrumbs-mobile-2', b.ptLg5, "scroll-inner"].join(' ')}>
            <Breadcrumbs items={breadcrumbs}/>
          </div>
        )
        : (<div className={[b.container,'breadcrumbs-mobile-2', b.pb3,"scroll-inner", b.px0].join(' ')}>
            <Breadcrumbs items={breadcrumbs}/>
          </div>
          )
        }
          <div className={`${b.container} ${b.pb2} ${b.positionRelative}`}>
            <div className={b.row}>
              <div className={b.colLg12}>
                <h1 className={`big-title ${b.pb3} ${b.w75} ${b.ptLg5} ${b.textWhite}`}>
                  {mysqlTerms.title}
                </h1>

                <div className={`intro-calculator ${b.p3} ${b.row} ${b.mx0}`}>
                  <div className={[b.colLg12,'blueToWhite',b.px1].join(' ')}>Jakiej pożyczki poszukujesz?</div>
                    { allMysqlTerms.nodes.map(({ term_normalized, terms_path, term,cluster}, i) => (
                      <div className={[b.colLg3,b.mt2,b.px1].join(' ')} key={i}>
                        <Link to={resolveUrl(terms_path, term_normalized)} >
                          <div className={['intro-calculator',b.py2,b.px3,b.dFlex,b.alignItemsCenter].join(' ')}>
                            <div>
                              {cluster === 'firms_1' ? <Icon size={40} icon="Loans" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                              {cluster === 'firms_2' ? <Icon size={40} icon="Installment-loan" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                              {cluster === 'firms_3' ? <Icon size={40} icon="Firm-folder" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                              {cluster === 'firms-cards' ? <Icon size={40} icon="Credit-cards" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                              {cluster === 'firms-without-bik_1' ? <Icon size={40} icon="Bik-without" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                            </div>
                            <span className='font-size-13'>{term}</span>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className={`${b.container} ${b.pt3} ${b.ptLg4}`}>
        <div className={["blog-content",b.row,'blueToGray', 'dark-li'].join(" ")}>
          <div className={[ b.colXl8,'dark-theme-listing'].join(' ')}>
          {mysqlTerms.description && (
            <BlogContent content={mysqlTerms.description} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          {mysqlTerms.description_alt && (
            <BlogContent content={mysqlTerms.description_alt} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          {mysqlTerms.description_additional && (
            <BlogContent content={mysqlTerms.description_additional} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          </div>
          <TableOfContents selector=".post-content h2[id]"/>
        </div>
      </div>

      {allMysqlBlog.nodes.length !== 0 && ( <>
        <section className={`${b.container} ${b.pt3} ${b.ptLg4} ${b.pb4} ${b.pbLg5} ${b.mbLg5}`}>
          <h2 className={["title", b.dBlock, b.mb4,b.mt3].join(' ')}>Treści powiązane z {mysqlTerms.term}</h2>
          <Posts items={allMysqlBlog.nodes} />
        </section>
      </>)}
    </Layout>
  )
}

export default NonBankLoans

export const query = graphql `
query NonBankLoans($mysqlId: Int!, $withoutUrls: [String]) {
  allMysqlTerms(filter: {cluster: {glob: "firms*"}, mysqlId: {ne: 105}}, sort: {fields: sort}, limit: 5) {
    nodes {
      term_normalized
      terms_path: path
      cluster
      term
    }
  }
  allMysqlBlog(filter: {url: {nin: $withoutUrls}, blogTerms:{elemMatch:{type: {eq: "category"}, mysqlId: {eq: $mysqlId}}}, description: {ne: null}, status: {eq: "publish"}}) {
    nodes {
      ...PostData
    }
  }
  mysqlTerms(mysqlId: {eq: $mysqlId}) {
    description_additional
    description_alt
    description
    title
    term
  }
  allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
    nodes {
      ...SeoData
    }
  }
}

`