import React, { useState, Fragment, useEffect } from 'react';
import { graphql } from 'gatsby';
import AllProducts from '../components/all-products';
import * as b from "../components/bootstrap.module.css"
import Grid, { GridSettings } from '../components/grid';
import ProductSortOptions from '../components/product-sort-options';
import ProductInputs from '../components/product-inputs';
import Posts from '../components/posts';
import BlogContent from '../components/blog-content';
import TableOfContents from '../components/table-of-contents';
import Breadcrumbs from "../components/breadcrumbs"
import Layout from "../components/layout";
import RankingShortcode from '../components/shortcodes/ranking-shortcode';
import Translate from '../components/translate';
import CommentsForm from '../components/comments-form';
import Icon from "../components/icon.tsx";
import Faq from '../components/faq';
import useMedia from '../hooks/useMedia';

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({ ...props }) => (
  <BaseHead {...props} description={props.data?.mysqlTerms?.description}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} />}
  </BaseHead>
);

const Products = ({
  data: { mysqlTerms, allMysqlBlog, mysqlRanking, faq },
  pageContext: { cluster, breadcrumbs, term_normalized, terms_path, ...settings },
  location: { pathname }
}) => {
  const [productSettings, setProductsSettings] = useState(settings);
  const [sortAlgorithm, setSortAlgorithm] = useState(null);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isFaq, setIsFaq] = useState(false);
  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);

  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('id');

        if (entry.isIntersecting && id === 'oferty')  {
          document.querySelector(`div.products-tab.sticky > div > div > div > div[data-id="powiazane"]`)?.classList.remove('products-tab-white')
          document.querySelector(`div.products-tab.sticky > div > div > div > div[data-id="info"]`)?.classList.remove('products-tab-white')
          document.querySelector(`div.products-tab.sticky > div > div > div > div[data-id="oferty"]`)?.classList.add('products-tab-white')
        } else if (entry.isIntersecting && id === 'info')  {
          document.querySelector(`div.products-tab.sticky > div > div > div > div[data-id="powiazane"]`)?.classList.remove('products-tab-white')
          document.querySelector(`div.products-tab.sticky > div > div > div > div[data-id="oferty"]`)?.classList.remove('products-tab-white')
          document.querySelector(`div.products-tab.sticky > div > div > div > div[data-id="info"]`)?.classList.add('products-tab-white')
        } else if (entry.isIntersecting && id === 'powiazane') {
          document.querySelector(`div.products-tab.sticky > div > div > div > div[data-id="oferty"]`)?.classList.remove('products-tab-white')
          document.querySelector(`div.products-tab.sticky > div > div > div > div[data-id="info"]`)?.classList.remove('products-tab-white')
          document.querySelector(`div.products-tab.sticky > div > div > div > div[data-id="powiazane"]`)?.classList.add('products-tab-white')
        }
      });
    },{
      rootMargin: '-60px 0px -60px 0px',
      threshold: 0,
    }
  );
    document.querySelectorAll('section').forEach((section) => {
      observer.observe(section);
    });
    return () => {
      observer.disconnect()
    };
  }, []);

  useEffect(() => {
    document.cookie = `firm_type=${cluster}; domain=.ofin.pl; path=/`;
  }, [cluster]);

  return (
    <Layout pathname={pathname}>
      <div>
        {isMobileResolution && (
          <div className={['breadcrumbs-mobile-2', b.ptLg5, "scroll-inner"].join(' ')}>
            <Breadcrumbs items={breadcrumbs} />
          </div>
        )}
        <section className={['intro-products', 'z-index-15', b.pt4, b.ptLg5].join(' ')}>
          {!isMobileResolution &&(<div className={[b.container,'breadcrumbs-mobile-2', b.pb3,"scroll-inner", b.px0].join(' ')}>
              <Breadcrumbs items={breadcrumbs} />
            </div>
          )}
          <div className={`${b.container} ${b.pb2} ${b.positionRelative}`}>
            <div className={b.row}>
              <div className={b.colLg12}>
                <h1 className={`big-title ${b.pb3} ${b.w75} ${b.ptLg5} ${b.textWhite}`}>
                  {mysqlTerms.term.startsWith('Kont') ? mysqlTerms.term : mysqlTerms.term + ' - porównywarka najlepszych ofert'}
                </h1>
                <BlogContent content={mysqlTerms.description} className={b.textWhite}/>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className={['products-tab', 'sticky'].join(' ')}>
        <div className={[b.container,].join(' ')}>
          <div className={[b.row, b.pt3, b.flexNowrap].join(' ')}>
            {cluster !== 'accounts_4' && cluster !== 'accounts_5' &&(
              <div className={[b.colAuto, b.pr1].join(' ')}>
                <div className={['products-tab-blue', 'products-tab-white'].join(' ')} data-id='oferty'>
                  <a href="#oferty" className={b.textWhite}>
                    <Icon size={24} icon="Star" className={[b.mr2, "icon-width"].join(" ")} />
                    Oferty
                  </a>
                </div>
              </div>
            )}
            <div className={[b.colAuto, (cluster === 'accounts_4' || cluster === 'accounts_5' ? b.pr0 : b.px0)].join(' ')}>
              <div className={['products-tab-blue', (cluster !== 'accounts_4' || cluster !== 'accounts_5' ? 'products-tab-white' : '')].join(' ')} data-id='info'>
                <a href="#info" className={b.textWhite}>
                  <Icon size={24} color='#FFFFFF' icon="Info" className={[b.mr2, "icon-width"].join(" ")} />
                  Informacje
                </a>
              </div>
            </div>
            {allMysqlBlog.nodes.length > 0 &&
              <div className={[b.colAuto, b.pl1].join(' ')}>
                <div className={['products-tab-blue'].join(' ')} data-id='powiazane'>
                  <a href="#powiazane" className={b.textWhite}>
                    <Icon size={24} color='#FFFFFF' icon="Login" className={[b.mr2, "icon-width"].join(" ")} />
                    Powiązane
                  </a>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <div className={`${b.container} ${b.pt3} ${b.ptLg4} products-3tabs`}>
        {cluster !== 'accounts_4' && cluster !== 'accounts_5' && (
          <section id="oferty">
            <h2 className={[b.pt0, b.mt0, 'title'].join(' ')}>Oferta {mysqlTerms.term}</h2>
            
            {!cluster.startsWith('accounts_') && !cluster.startsWith('cards') ?
              (<div className={`intro-calculator ${b.p3} ${b.pLg4} ${b.mb5}`}>
                <ProductInputs
                  settings={productSettings}
                  onChangeInput={setProductsSettings}
                  productName={`${productSettings.types}${productSettings?.subtypes?.[productSettings.types] || ''}`}
                  type={settings.types}
                />
              </div>) : ''}
            
            <Grid>
              {({ grid, setGrid }) => <>
                <GridSettings grid={grid} setGrid={setGrid}>
                  <ProductSortOptions cluster={cluster} setSortAlgorithm={setSortAlgorithm} />
                </GridSettings>

                <AllProducts 
                  grid={grid} 
                  settings={productSettings}
                  sortAlgorithm={sortAlgorithm} 
                />
              </>}
            </Grid>
          </section>
        )}

        {(mysqlTerms.description_additional || mysqlTerms.description_alt) && <div className={`${b.row} product-page blog-content`}>
          <section className={`${b.colXl8} ${b.mb4} blackToGray dark-theme-listing`}>
            <section id='info'>
            {<RankingShortcode value={`${mysqlTerms.description_alt} ${mysqlTerms.description_additional}`} type={mysqlRanking?.url}>
              {(fragments) => fragments?.map(({ slice, ranking }, i) => (<Fragment key={i}>
                {slice && <BlogContent content={slice} />}
                {ranking}
              </Fragment>))}
            </RankingShortcode>}
            {faq?.nodes.length > 0 && (
              <>
                <h2 id="section-title-15">
                  Czesto zadawane pytania
                </h2>
                {faq.nodes.map(({ title, text }, i) => (
                  <Faq title={title} text={text} key={i} />
                ))}
                <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                  <button className={[b.btn, "btn-light", "btn-cta", "button-256",].join(" ")} onClick={() => { toggleForm(); toggleFaq(true); }}>
                    <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")} />
                    <Translate tKey="Ask your own question" />
                  </button>
                </div>
              </>
            )}

            <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false); toggleFaq(false); }} formID={mysqlTerms.mysqlId} commentType="term" faq={isFaq} />
            </section>
          </section>

          {<TableOfContents selector=".post-content h2[id]" />}
        </div>}
      </div>


      {allMysqlBlog.nodes.length > 0 && (<>
        <section className={`${b.container} ${b.pt3} ${b.ptLg4} ${b.pb4} ${b.pbLg5} ${b.mbLg5}`} id="powiazane">
          <h2 className={["title", b.dBlock, b.mb4, b.mt3].join(' ')}>Treści powiązane z {mysqlTerms.term}</h2>
          <Posts items={allMysqlBlog.nodes} />
        </section>
      </>)}
    </Layout>
  )
}

export const query = graphql`
  query ProductQuery($mysqlId: Int!, $withoutUrls: [String]) {
    allMysqlBlog(filter: {url: {nin: $withoutUrls}, blogTerms:{elemMatch:{type: {eq: "category"}, mysqlId: {eq: $mysqlId}}}, description: {ne: null}, status: {eq: "publish"}}) {
      nodes {
        ...PostData
      }
    }

    mysqlRanking(term_id: { eq: $mysqlId }) {
      url
    }

    mysqlTerms(mysqlId: { eq: $mysqlId }) {
      description_additional
      description_alt
      description
      title
      mysqlId
      term
      cluster
    }

    faq: allMysqlFaq(
      filter: {type: {eq: "term"}, item: {eq: $mysqlId}, answer: {ne: "null"}}
      sort: {fields: priority, order: DESC}
    ) {
      nodes {
       title: question
       text: answer
      }
    }

    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
      nodes {
        ...SeoData
      }
    }
  }
`;

export default Products;
