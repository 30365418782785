import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as b from "../../bootstrap.module.css";

const ElixirCalculator = () => {
  const { elixir } = useStaticQuery(graphql`
    query ElixirCalculator {
      elixir: allMysqlElixirBank {
        nodes {
          name
          elixir_out_first
          elixir_out_second
          elixir_out_third
          elixir_in_first
          elixir_in_second
          elixir_in_third
        }
      }
    }
  `);

  const [userTransfer, setUserTransfer] = useState(Date.now());
  const [outBank, setOutBank] = useState(elixir.nodes[0].name);
  const [inBank, setInBank] = useState(elixir.nodes[1].name);
  const [result, setResult] = useState();

  const updateTime = (e) => {
    const transferDate = new Date(userTransfer);

    transferDate.setHours(e.target.valueAsDate.getUTCHours());
    transferDate.setMinutes(e.target.valueAsDate.getUTCMinutes());

    setUserTransfer(transferDate.getTime());
  };

  const updateDate = (e) => {
    const transferDate = new Date(userTransfer);

    transferDate.setFullYear(e.target.valueAsDate.getUTCFullYear());
    transferDate.setMonth(e.target.valueAsDate.getUTCMonth());
    transferDate.setDate(e.target.valueAsDate.getUTCDate());

    setUserTransfer(transferDate.getTime());
  };

  const updateOutBank = (e) => {
    setOutBank(e.target.value);
  };

  const updateInBank = (e) => {
    setInBank(e.target.value);
  };

  useEffect(() => {
    const outDate = new Date(calculateHolidays(calculateTimeOut(userTransfer, outBank, elixir)));
    const inDate = new Date(calculateTimeIn(outDate, inBank, elixir));

    setResult(inDate.getTime());
  }, [elixir, userTransfer, outBank, inBank])
  
  return (
    <form className={["product-inputs", "credit-calculator", "blueToGray", b.mt4, b.p3].join(" ")} onSubmit={(e) => e.preventDefault()}>
      <h2 className={['title', b.mb3].join(' ')} style={{ fontWeight: 500 }}>Kalkulator ELIXIR - sprawdz kiedy przyjdzie przelew</h2>

      <div className={b.row}>
        <div className={b.colMd6}>
          <label htmlFor="transfer-time" className={b.mb2}>
            Wybierz godzinę:
          </label>

          <input 
            id="transfer-time" 
            type="time"
            className={`form-control ${b.formControl} ${b.py2} blackToWhite`}
            value={inputTimeValue(userTransfer)} 
            onChange={updateTime}
            onClick={showInputPicker}
          />
        </div>

        <div className={[b.colMd6, b.mt3, b.mtMd0].join(' ')}>
          <label htmlFor="transfer-date" className={b.mb2}>
            Wybierz datę:
          </label>

          <input 
            id="transfer-date" 
            type="date" 
            className={`form-control ${b.formControl} ${b.py2} blackToWhite`}
            value={inputDateValue(userTransfer)} 
            onChange={updateDate}
            onClick={showInputPicker}
          />
        </div>
      </div>

      <div className={[b.row, b.mt3].join(' ')}>
        <div className={b.colMd6}>
          <label htmlFor="in-bank" className={b.mb2}>
            Wybierz bank nadawcy:
          </label>

          <select 
            id="in-bank" 
            className={["select", b.py2, b.card].join(" ")}
            value={outBank} 
            onChange={updateOutBank} 
          >
            {elixir.nodes.map(({ name }, i) => (
              <option key={i} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>

        <div className={[b.colMd6, b.mt3, b.mtMd0].join(' ')}>
          <label htmlFor="out-bank" className={b.mb2}>
            Wybierz bank odbiorcy:
          </label>

          <select 
            id="out-bank" 
            className={["select", b.py2, b.card].join(" ")}
            value={inBank} 
            onChange={updateInBank}
          >
            {elixir.nodes.map(({ name }, i) => (
              <option key={i} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {result && <div className={["details", b.mt4, b.p3].join(' ')}>
        <p className={b.my0}>
          Szacowana data przelewu z 
          banku <strong>{inBank}</strong> do 
          banku <strong>{outBank}</strong> to <strong> {formatResult(result)}</strong>
        </p>
      </div>}
    </form>
  )
}

const inputTimeValue = (value) => {
  const date = new Date(value);

  const hour = date.getHours().toString().padStart(2, 0);
  const minute = date.getMinutes().toString().padStart(2, 0);

  return `${hour}:${minute}`;
}

const inputDateValue = (value) => {
  const date = new Date(value);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, 0);
  const day = date.getDate().toString().padStart(2, 0);

  return `${year}-${month}-${day}`;
}

const calculateHolidays = (value) => {
  const date = new Date(value);
  const weekDay = date.getDay();

  if (weekDay === 6 || weekDay === 0) {
    date.setDate(date.getDate() + (weekDay === 6 ? 2 : 1));

    date.setHours(0);
    date.setMinutes(0);
  }

  return date.getTime();
}

const calculateTimeOut = (transferDate, outBank, banks) => {
  const date = new Date(transferDate);
  const paymentBank = banks.nodes.find(({ name }) => name === outBank);
  paymentBank.outFirst = new Date(`${inputDateValue(date)} ${paymentBank.elixir_out_first}`);
  paymentBank.outSecond = new Date(`${inputDateValue(date)} ${paymentBank.elixir_out_second}`);
  paymentBank.outThird = new Date(`${inputDateValue(date)} ${paymentBank.elixir_out_third}`);
  
  if (date.getTime() < paymentBank.outFirst.getTime()) {
    return paymentBank.outFirst.getTime();
  }

  if (date.getTime() < paymentBank.outSecond.getTime()) {
    return paymentBank.outSecond.getTime();
  }

  if (date.getTime() < paymentBank.outThird.getTime()) {
    return paymentBank.outThird.getTime();
  }

  paymentBank.outFirst.setDate(paymentBank.outFirst.getDate() + 1);

  return paymentBank.outFirst.getTime();
}

const calculateTimeIn = (outDate, inBank, banks) => {
  const date = new Date(outDate);
  const receivingBank = banks.nodes.find(({ name }) => name === inBank);
  receivingBank.inFirst = new Date(`${inputDateValue(date)} ${receivingBank.elixir_in_first}`);
  receivingBank.inSecond = new Date(`${inputDateValue(date)} ${receivingBank.elixir_in_second}`);
  receivingBank.inThird = new Date(`${inputDateValue(date)} ${receivingBank.elixir_in_third}`);

  if (date.getTime() < receivingBank.inFirst.getTime()) {
    return receivingBank.inFirst.getTime();
  }

  if (date.getTime() < receivingBank.inSecond.getTime()) {
    return receivingBank.inSecond.getTime();
  }

  if (date.getTime() < receivingBank.inThird.getTime()) {
    return receivingBank.inThird.getTime();
  }

  receivingBank.inFirst.setDate(receivingBank.inFirst.getDate() + 1);

  return receivingBank.inFirst.getTime();
}

const formatResult = (value) => {
  const date = new Date(value);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, 0);
  const day = date.getDate().toString().padStart(2, 0);

  const hour = date.getHours().toString().padStart(2, 0);
  const minute = date.getMinutes().toString().padStart(2, 0);

  const weekDay = ['niedziela', 'poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota'][date.getDay()];

  return `${weekDay}, ${day}.${month}.${year} ${hour}:${minute}`;
}

const showInputPicker = (e) => {
  e.target.showPicker();
};

export default ElixirCalculator;