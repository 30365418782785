import React from 'react';
import Layout from '../components/layout';
import * as b from "../components/bootstrap.module.css"
import BlogContent from '../components/blog-content';
import Team from '../components/team';
import Breadcrumbs from '../components/breadcrumbs';
import { graphql } from 'gatsby';

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const AboutUs = ({ 
    data: { mysqlTerms }, 
    pageContext: { breadcrumbs }, 
}) => {
    return (
        <Layout>
            <div className={[b.container,'breadcrumbs-mobile-2', b.py3, b.ptLg5, "scroll-inner", b.px1, b.mx0, b.mxSmAuto].join(' ')}>
                <Breadcrumbs items={breadcrumbs} theme="white" />
            </div>

            <div className={[b.container, b.mt4].join(' ')}>
                <div className={b.row}>
                    <div className={b.colXl9}>
                        <h1 className={['big-title', b.mb4].join(' ')}>{mysqlTerms.title}</h1>
                        <BlogContent content={mysqlTerms.description} />
                        <BlogContent content={mysqlTerms.description_additional} />
                    </div>
                </div>
            </div>

            <Team>
                <h2 className={['title', b.mb4].join(' ')}>Zespół</h2>
            </Team>
        </Layout>
    );
};

export default AboutUs;

export const query = graphql`
    query AboutUsPage($mysqlId: Int!) {
        mysqlTerms(mysqlId: {eq: $mysqlId}) {
            description_additional
            description
            title
        }
        allMysqlSeo(filter: {item_id: {eq: $mysqlId}, item_type: {eq: "term"}}) {
            nodes {
              ...SeoData
            }
        }
    }
`