const decimal = new Intl.NumberFormat('fr', { 
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const isString = (value) => {
  return typeof value === "string" && value.length > 0;
};

const isNumber = (value) => {
  return Number.isFinite(value);
};

const moneyFormat = (value) => {
  if (isNumber(value) || isString(value)) {
    return `${decimal.format(value)} zł`;
  }
  
  return '-';
};

const percentFormat = (value) => {
  if (isNumber(value) || isString(value)) {
    return `${decimal.format(value)} %`;
  }
  
  return '-';
};

const textFormat = (value, suffix = '') => {
  if (isNumber(value) || isString(value)) {
    return `${value} ${suffix}`;
  }

  return '-';
};

const moneyBetweenFormat = (...values) => {
  const result = values
    .filter((value) => isNumber(value) || isString(value))
    .map((value) => decimal.format(value))
    .join(' / ');

  return result.length > 0 
    ? `${result} zł`
    : '-';
}

const percentBetweenFormat = (...values) => {
  const result = values
    .filter((value) => isNumber(value) || isString(value))
    .map((value) => decimal.format(value))
    .join(' / ');

  return result.length > 0 
    ? `${result} %`
    : '-';
}

export {
  moneyFormat,
  percentFormat,
  textFormat,
  moneyBetweenFormat,
  percentBetweenFormat,
};
