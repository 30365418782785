import React from 'react';
import { graphql, Link } from 'gatsby'
import * as b from "../components/bootstrap.module.css"
import Breadcrumbs from "../components/breadcrumbs";
import useMedia from "../hooks/useMedia";
import Icon from "../components/icon.tsx"
import resolveUrl from '../utils/urls/resolve-url';
import Layout from "../components/layout";
import BlogContent from "../components/blog-content.js";

export { default as Head } from '../components/base-head';

const Rankings = ({
  data: { mysqlTerms, allMysqlRanking },
  pageContext: { breadcrumbs },
  location: { pathname }
}) => {

  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);

  return (
    <Layout pathname={pathname}>
      {isMobileResolution ? (
        <div className={['breadcrumbs-mobile-2', b.ptLg5, "scroll-inner"].join(' ')}>
          <Breadcrumbs items={breadcrumbs} theme='white'/>
        </div>
      )
      : (<div className={[b.container,'breadcrumbs-mobile-2', b.py3, b.ptLg5,b.mt3, "scroll-inner", b.px0].join(' ')}>
          <Breadcrumbs items={breadcrumbs} theme='white'/>
        </div>
        )
      }
      <section className='firm-page'>
        <div className={["local-firm-page", "blog-content", "post-content"].join(" ")}>
            <div className={[b.container, b.mb5, b.pbLg5].join(' ')}>
                <h1>{mysqlTerms.title}</h1>

                <BlogContent content={mysqlTerms.description} />

                <div className={[b.row,b.mt3,b.mtLg4].join(' ')}>
                    {allMysqlRanking.nodes.map(({name, url, prefix_url, intro, term_id}, i) => (
                        <div className={[b.colLg6, b.mt3].join(' ')} key={i}>
                            <div className={[b.dFlex, b.justifyContentCenter, b.alignItemsCenter, 'rankings-bg'].join(' ')}>
                                {term_id === 35 &&( <Icon size={64} icon="Loans" /> )}
                                {term_id === 33 &&( <Icon size={64} icon="Mortgage" /> )}
                                {term_id === 36 &&( <Icon size={64} icon="Installment-loan" /> )}
                                {term_id === 37 &&( <Icon size={64} icon="Cash-loan" /> )}
                                {term_id === 38 &&( <Icon size={64} icon="Bank-account" /> )}
                                {term_id === 101 &&( <Icon size={64} icon="Mortgage" /> )}
                                {term_id === 102 &&( <Icon size={64} icon="Firm-folder" /> )}
                                {term_id === 113 &&( <Icon size={64} icon="Consolidation-loan" /> )}
                                {term_id === 114 &&( <Icon size={64} icon="Piggy-bank" /> )}
                            </div>
                            <div className={[b.p3, 'blackToWhite', 'rankings-box'].join(' ')}>
                                <Link to={resolveUrl(prefix_url, url)} >
                                    <h4 className=''>{name}</h4>
                                </Link>
                                <Link to={resolveUrl(prefix_url, url)}>
                                    <p className={['font-size-13', b.mt0, b.fontWeightNormal].join(' ')}>{intro}</p>
                                </Link>
                            </div>
                        </div>
                        ))
                    }
                </div>

                <BlogContent content={mysqlTerms.description_additional} />
            </div>
        </div>
      </section>
    </Layout>
  )
}

export default Rankings

export const query = graphql`
  query Rankings($mysqlId: Int! ) {
    allMysqlRanking(filter: { active: {eq: 1}, hidden: { ne: 1 }, term_id: {ne: null}}) {
        nodes {
          name
          url
          prefix_url
          type
          intro
          term_id
      }
    }
    mysqlTerms(mysqlId: {eq: $mysqlId }) {
      title
      description
      description_additional
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
      nodes {
        ...SeoData
      }
    }
  }
`