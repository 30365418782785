import React from "react";
import { graphql } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import Posts from "../components/posts";
import Thumbnail from "../components/thumbnail";
import BlogContent from "../components/blog-content";
import Breadcrumbs from "../components/breadcrumbs"
import Translate from "../components/translate";
import Layout from "../components/layout";
import Icon from "../components/icon.tsx"
import useMedia from "../hooks/useMedia";

import BaseHead from "../components/base-head";
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props} 
    title={`${props.data.mysqlAuthor.name} - ekspert finansowy | ofin.pl`} 
    description={props.data.mysqlAuthor.description} 
  >
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/author/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const Author = ({ 
  data: { mysqlAuthor, categories }, 
  pageContext : { breadcrumbs },
  location: { pathname },
}) => {
  const {
    description,
    name,
    university,
    degree,
    education,
    social_li,
    mysqlImage,
    mysqlId,
    title,
  } = mysqlAuthor;

  const blogs = mysqlAuthor.blogs.filter(({ promo_bonus, external }) => external.length === 0 && (promo_bonus === null || promo_bonus?.trim()?.length === 0));
  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);
  
  return (
    <Layout pathname={pathname}>
      {isMobileResolution ? (
        <div className={['breadcrumbs-mobile-2', b.ptLg5, "scroll-inner"].join(' ')}>
          <Breadcrumbs items={breadcrumbs} theme='white'/>
        </div>
      )
      : (<div style={{ position: 'relative', zIndex: 10 }} className={[b.container,'breadcrumbs-mobile-2', b.py3, b.ptLg5, "scroll-inner", b.px0].join(' ')}>
          <Breadcrumbs items={breadcrumbs} theme='white'/>
        </div>
        )
      }
      <article className={["firm-page",b.pt4].join(' ')}>
        <div className={[b.container, "blog-content", "post-content"].join(" ")}>
          {mysqlImage && (
            <>
              <div className={["imgBg-box",b.dFlex,b.alignItemsCenter,b.justifyContentCenter].join(' ')}>
                <Thumbnail image={mysqlImage} className="imgBg" alt={name + ' - tło'}/>
              </div>
              <div className={"imgPortrait"}>
                <Thumbnail image={mysqlImage} alt={name}/>
              </div>
            </>
          )}
          <div className={["author-box",b.mt2, b.mb5, b.pbLg5].join(' ')}>
            <h1 className={["big-title",'blackToWhite'].join(' ')}>{name}</h1>
            
            {title && (
              <div className="div-author">
                <span className={"author-blue-span"}>{title}</span>
              </div>
            )}

            <table>
              <tbody>
                <tr>
                  <td><Translate tKey="Education"/></td>
                  {education && <td>{education}</td> }
                </tr>

                <tr>
                  <td><Translate tKey="University name"/></td>
                  {university && <td>{university}</td>}
                </tr>

                <tr>
                  <td><Translate tKey="Degree"/></td>
                  {degree && <td>{degree}</td>}
                </tr>
              </tbody>
            </table>

            {description && <BlogContent content={description} className='blueToGray' /> }
            <div className={[b.justifyContentCenter, b.row].join(" ")}>
              {social_li && (
                <>
                  <div className={b.colLgAuto}>
                      <a href={social_li} target="_blank" rel="noreferrer" className={["blueToWhite",'author-button',b.fontWeightNormal].join(' ')}>
                        <Icon size={20} icon="LinkedIn-outline" className={[b.mr1, "icon-blog"].join(" ")}/>
                        {name} <Translate tKey="on LinkedIn"/>
                      </a>
                  </div>
                </>
              )}
            </div>
          </div>

          {blogs.length > 0 && mysqlId !== 2704 &&(
            <aside className={[b.mb4, b.pbLg5, b.mbXl5, "posts"].join(" ")}>
              <h2>Najnowsze wpisy użytkownika <span className="blue-name">{name}</span> w ofin.pl</h2>
              
              <Posts items={blogs} />
            </aside>
          )}
        </div>
      </article>
    </Layout>
  );
};

export default Author;

export const query = graphql`
  query QueryAuthor($mysqlId: Int!) {
    mysqlAuthor(mysqlId: { eq: $mysqlId }, blogs: {elemMatch: {promo_bonus: {eq: ""}, status: {eq: "publish"}, visible: {eq: 1}}}) {
      description
      name
      education
      degree
      social_li
      mysqlId
      university
      title
      mysqlImage {
        childImageSharp {
          gatsbyImageData(width: 275, height: 275)
        }
      }
      blogs {
        ...PostData
        promo_bonus
        external
      }
    }
  }
`;
