import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import Comments from "../components/comments";
import CommentsForm from "../components/comments-form";
import Thumbnail from "../components/thumbnail";
import Icon from "../components/icon.tsx";
import AdvDis from "../components/adv-dis";
import Breadcrumbs from "../components/breadcrumbs"
import Out from "../components/out-product";
import Translate from "../components/translate";
import scrollTo from "gatsby-plugin-smoothscroll";
import useDescription from "../hooks/useDescription";
import { SCROLL_CLASS, DIRECTION_DOWN_CLASS, DIRECTION_UP_CLASS, } from "../utils/classes";
import resolveProductUrl from "../utils/urls/resolve-product-url";
import resolveLocalfirmUrl from "../utils/urls/resolve-localfirm-url";
import Layout from "../components/layout";
import resolveUrl from "../utils/urls/resolve-url";
import useMedia from "../hooks/useMedia";
import Faq from "../components/faq";

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";
import { moneyFormat } from "../utils/format";

export const Head = ({ ...props }) => {
  const name = props?.data?.mysqlBank?.name;

  const title = `${name} - oferta i placówki`;
  const description = `Najważniejsze informacje o ${name}, zobacz lokalizacje oddziałów, godziny sesji przychodzących i wychodzących przelewów Elixir i dostępne produkty finansowe w banku. Dowiedz się o zaletach i wadach banku`;
  
  return (
    <BaseHead 
      {...props}
      title={title}
      description={description}
    >
      {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/bank/${props.pageContext.mysqlId}.png`} />}
    </BaseHead>
  );
}

const Bank = ({
  data: { mysqlBank, allMysqlLocalfirms, faq, allMysqlComments, allMysqlDescriptionBank, bankPromotions, allSitePage },
  pageContext: { breadcrumbs },
  location: { pathname },
}) => {
  const {
    advantages,
    description_advantages,
    disadvantages,
    swift,
    mysqlImage,
    name,
    address,
    rating,
    accounts,
    card,
    credits,
    capital,
    established_year,
    workhours,
    app,
    phone,
    number_of_establishments,
    owner,
    mysqlId,
    url,
    login_url,
  } = mysqlBank;

  const [mobileIsOpen, setMobileIsOpen] = useState(false);
  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);

  let idTimeout = null;

  const scroll = (id) => {
    clearTimeout(idTimeout);

    document.body.classList.remove(DIRECTION_UP_CLASS);
    document.body.classList.add(SCROLL_CLASS, DIRECTION_DOWN_CLASS);
    scrollTo(`#${id}`);

    idTimeout = setTimeout(
      () => document.body.classList.remove(SCROLL_CLASS),
      2000
    );
  };
  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const [isFaq, setIsFaq] = useState(false);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)
  const d = useDescription(allMysqlDescriptionBank.nodes);
  const bankPromoUrl = allSitePage.nodes.filter(page => page.path.includes(resolveUrl(url)))[0]?.path

  return (
    <Layout pathname={pathname}>
      <div className={["bank-header", 'intro-products2', b.dFlex, b.justifyContentCenter].join(" ")} />
      {isMobileResolution ? (
        <div className={['breadcrumbs-mobile-2', b.ptLg5, "scroll-inner"].join(' ')}>
          <Breadcrumbs items={breadcrumbs} />
        </div>
      )
      : (<div className={[b.container,'breadcrumbs-mobile-2', b.py3, b.ptLg5, "scroll-inner", b.px0].join(' ')}>
          <Breadcrumbs items={breadcrumbs} />
        </div>
        )
      }
      <article className="firm-page">
        <div className={["local-firm-page", "blog-content", "post-content"].join(" ")}>
          <div className={b.container}>
            <div className={b.row}>
              <div className={b.colLg8}>
                <h1 className={["big-title", b.mt4, b.textWhite].join(" ")}>{name}</h1>
                {mysqlImage && (
                  <>
                    <div className={"bank-box-mobile"}>
                      <div className={"bank-padding-box"}>
                        <div className='bank-padding-img'>
                          <Thumbnail image={mysqlImage} alt={name} />
                        </div>
                        <div className={[b.dFlex, b.pb2, b.justifyContentCenter, b.mt3, "cursor-pointer "].join(" ")} onClick={() => scroll('addComment')} role="button" tabIndex={0}>
                          <div className="stars-container">
                            {rating > 0 && (
                              <div className="star-box" style={{ width: `${rating}%` }}>
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" />
                              </div>
                            )}
                            <div className='empty-star-box'>
                              <Icon size={20} icon="Empty-star" className={b.mr1} />
                              <Icon size={20} icon="Empty-star" className={b.mr1} />
                              <Icon size={20} icon="Empty-star" className={b.mr1} />
                              <Icon size={20} icon="Empty-star" className={b.mr1} />
                              <Icon size={20} icon="Empty-star" />
                            </div>
                          </div>
                        </div>
                        <span className={[b.dFlex, b.justifyContentCenter, 'font-size-20'].join(" ")}>
                          {(rating > 0 ? rating / 20 : 0).toFixed(1)}
                        </span>
                      </div>
                      <button className={[b.btn, "btn-light", b.mt2].join(" ")} onClick={() => { toggleForm(); toggleFaq(false); }}>
                        <Icon size={20} icon="Logout" className={[b.mr1, "icon-blog"].join(" ")} />
                        <Translate tKey="Add opinion" />
                      </button>
                      <Out className={[b.btn, "btn-success", "btn-cta", b.mt2, b.textWhite, b.justifyContentCenter, b.alignItemsCenter, b.dFlex, b.fontWeightNormal].join(' ')} defaultDescription="Contact form" item={mysqlBank} defaultIcon="Pen" />
                    </div>
                  </>
                )}
                {d.lead && d.lead.description && (
                  <div className={['blackToWhite', 'font-size-18', b.fontWeightNormal].join(' ')} dangerouslySetInnerHTML={{ __html: d.lead.description }} />
                )}
                <div className={["bank-box", 'bank-box-relative'].join(' ')}>
                  <div className={['font-size-20', b.mb3, 'blackToWhite'].join(' ')}>{name}- dane dotyczace banku</div>
                  <div className={b.row}>
                    {address &&(
                      <div className={[b.colMd6]}>
                        <div className={b.dFlex}>
                          <div style={{ height: '40px', width: '40px', marginRight: '12px' }}>
                            <Icon size={40} icon="HQ" className={[b.mr2, "icon-blog"].join(" ")} />
                          </div>
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="Point address" /></small>
                            <p className='firm-box-text'>{address}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {capital && (
                      <div className={[b.colMd6]}>
                        <div className={b.dFlex}>
                          <div style={{ height: '40px', width: '40px', marginRight: '12px' }}>
                            <Icon size={40} icon="Cash-register" className={[b.mr2, "icon-blog"].join(" ")} />
                          </div>
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="The amount of assets" /></small>
                            <p className='firm-box-text'>{moneyFormat(capital)}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {established_year && (
                      <div className={[b.colMd6]}>
                        <div className={b.dFlex}>
                          <div style={{ height: '40px', width: '40px', marginRight: '12px' }}>
                            <Icon size={40} icon="License" className={[b.mr2, "icon-blog"].join(" ")} />
                          </div>
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="Established year" /></small>
                            <p className='firm-box-text'>
                              {established_year ? established_year : <Translate tKey="no data" />}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {owner && (
                      <div className={[b.colMd6]}>
                        <div className={b.dFlex}>
                          <div style={{ height: '40px', width: '40px', marginRight: '12px' }}>
                            <Icon size={40} icon="Property" className={[b.mr2, "icon-blog"].join(" ")} />
                          </div>
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="Who does the bank belong to?" /></small>
                            <p className='firm-box-text'>{owner ? owner : <Translate tKey="no data" />}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {workhours && (
                      <div className={[b.colMd6]}>
                        <div className={b.dFlex}>
                          <div style={{ height: '40px', width: '40px', marginRight: '12px' }}>
                            <Icon size={40} icon="Work-hours" className={[b.mr2, "icon-blog"].join(" ")} />
                          </div>
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="Opening hours" /></small>
                            <p className='firm-box-text'>{workhours}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* {number_of_establishments &&(
                      <div className={[b.colMd6]}>
                        <div className={b.dFlex}>
                          <div style={{ height: '40px', width: '40px', marginRight: '12px' }}>
                            <Icon size={40} icon="Branches" className={[b.mr2, "icon-blog"].join(" ")} />
                          </div>
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="Number of branches" /></small>
                            <p className='firm-box-text'>{number_of_establishments}</p>
                          </div>
                        </div>
                      </div>
                    )} */}
                    {phone && (
                      <div className={[b.colMd6]}>
                        <div className={b.dFlex}>
                          <div style={{ height: '40px', width: '40px', marginRight: '12px' }}>
                            <Icon size={40} icon="Helpline" className={[b.mr2, "icon-blog"].join(" ")} />
                          </div>
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="Helpline - individual customers" /></small>
                            <p className='firm-box-text'>{phone}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {swift && (
                      <div className={[b.colMd6]}>
                        <div className={b.dFlex}>
                          <div style={{ height: '40px', width: '40px', marginRight: '12px' }}>
                            <Icon size={40} icon="Bank-building" className={[b.mr2, "icon-blog"].join(" ")} />
                          </div>
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="BIC/Swift code" /></small>
                            <p className='firm-box-text'>{swift}</p>
                          </div>
                        </div>
                      </div>
                    )}

                    {login_url &&
                      (
                        <div className={[b.colMd6]}>
                          <div className={b.dFlex}>
                            <div style={{ height: '40px', width: '40px', marginRight: '12px' }}>
                              <Icon size={40} icon="WWW" className={[b.mr2, "icon-blog"].join(" ")} />
                            </div>
                            <div className={b.justifyContentStart}>
                              <small><Translate tKey="Website address" /></small>
                              <p className='firm-box-text'>
                                <span className={["blueToWhite", b.fontWeightNormal].join(' ')}> {login_url} </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    {bankPromotions.totalCount > 0 && bankPromoUrl &&
                      <div className={[b.colMd12, b.mt3].join(' ')}>
                        <Link to={bankPromoUrl}>
                          <div className={['account-promotions-btn', b.py1, b.px2, b.dFlex, b.justifyContentBetween, b.alignItemsCenter, 'cursor-pointer', b.fontWeightNormal].join(' ')}>
                            <div>
                              <Icon size={40} icon="Coins" className={[b.mr2, "icon-blog"].join(" ")} />
                              Wszystkie promocje {name}
                            </div>
                            {!isMobileResolution ?
                              <div>
                                Sprawdź
                                <Icon size={20} icon="Angle-right" className={[b.ml1, "icon-blog"].join(" ")} />
                              </div>
                              : ''
                            }
                          </div>
                        </Link>
                      </div>
                    }
                  </div>
                </div>

                {d.info && d.info.description && (
                  <>
                    <h2>{d.info.header}</h2>
                    <BlogContent content={d.info.description} />
                  </>
                )}

                {d.elixir && d.elixir.description && (
                  <>
                    <h2>{d.elixir.header}</h2>
                    <BlogContent content={d.elixir.description} />
                  </>
                )}

                {advantages && disadvantages && (
                  <div className="bank">
                    <AdvDis advantages={advantages} disadvantages={disadvantages}>
                      <h2><Translate tKey="Adv and disadv" /> {name}</h2>

                      {description_advantages && <BlogContent content={description_advantages} className='blueToGray' />}
                    </AdvDis>
                  </div>
                )}

                {faq?.nodes.length > 0 && (
                  <>
                    <h2><Translate tKey="The most important information about" /> {name}</h2>

                    {faq.nodes.map(({ title, text }, i) => <Faq title={title} text={text} key={i} />)}

                    <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                      <button className={[b.btn, "btn-light", "btn-cta", "button-256",].join(" ")} onClick={() => { toggleForm(); toggleFaq(true); }}>
                        <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")} />
                        <Translate tKey="Ask your own question" />
                      </button>
                    </div>
                  </>
                )}
                {allMysqlLocalfirms.group.length && (
                  <>
                    {d?.institutions && <h2>{d.institutions.header}</h2>}
                    {d?.institutions?.description && <BlogContent content={d.institutions.description} />}

                    <div className={"bank-box"}>
                      <div className={b.row}>
                        {allMysqlLocalfirms.group.map(({ nodes }) =>
                          nodes.map((node, i) => (
                            <div className={[b.colMd4, b.dFlex, "blackToWhite", b.my2,].join(" ")} key={i}>
                              <Icon size={40} icon="Branches" className={["icon-blog", b.mr2].join(" ")} />
                              <Link to={resolveLocalfirmUrl({ bank: mysqlBank, ...node })} className={b.fontWeightNormal}>
                                <p className={[b.pt2, b.textCapitalize].join(' ')}>
                                  {node.name}
                                </p>
                              </Link>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </>
                )}

                <Comments items={allMysqlComments.nodes} title="standard" itemName={name} formID={mysqlId} commentType="bank" />
                <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false); toggleFaq(false); }} formID={mysqlId} commentType="bank" faq={isFaq} />
              </div>

              {/* druga połowa strona */}

              <div className={[b.colLg4, b.mbLg5, b.pbLg5].join(' ')}>
                {mysqlImage && (
                  <div className={["bank-box-desktop", "margin-top-bank", 'sticky-box', "cursor-pointer "].join(" ")} >
                    <div className={"bank-padding-box"}>
                      <div className='bank-padding-img'>
                        <Thumbnail image={mysqlImage} alt={name} />
                      </div>
                      <div className={[b.dFlex, b.pb2, b.justifyContentCenter, b.mt3,].join(" ")} onClick={() => scroll('addComment')} role="button" tabIndex={0}>
                        <div className="stars-container">
                          {rating > 0 && (
                            <div className="star-box" style={{ width: `${rating}% ` }}>
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" />
                            </div>
                          )}
                          <div className='empty-star-box'>
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" />
                          </div>
                        </div>
                      </div>
                      <span className={[b.dFlex, b.justifyContentCenter, 'blackToWhite', 'font-size-20'].join(" ")}>
                        {(rating > 0 ? rating / 20 : 0).toFixed(1)}
                      </span>
                    </div>
                    <button className={[b.btn, "btn-light", b.mt2].join(" ")} onClick={() => { toggleForm(); toggleFaq(false); }}>
                      <Icon size={20} icon="Logout" className={[b.mr1, "icon-blog"].join(" ")} />
                      <Translate tKey="Add opinion" />
                    </button>
                    <Out className={[b.btn, "btn-success", "btn-cta", b.textWhite,
                    b.justifyContentCenter, b.alignItemsCenter, b.dFlex, b.fontWeightNormal].join(' ')}
                      defaultDescription="Contact form" item={mysqlBank} defaultIcon="Pen" />
                  </div>
                )}
                <div className={["local-firm-box-1", 'sticky-box2'].join(' ')}>
                  <h3><Translate tKey="Products" /> {name}</h3>
                  {credits && (
                    credits.map(({ type, name, ...credit }, i) => (
                      <div className={[b.dFlex, b.justifyContentBetween].join(' ')} key={i}>
                        <Link to={resolveProductUrl({ bank: mysqlBank, ...credit })}>
                          <div className={[b.dFlex, b.mr3].join(" ")}>
                            {type === 2 ? <Icon size={40} icon="Cash-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 1 ? <Icon size={40} icon="Mortgage" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 4 ? <Icon size={40} icon="Consolidation-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 3 ? <Icon size={40} icon="Car-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 5 ? <Icon size={40} icon="Deposits" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{name}</p>
                          </div>
                        </Link>
                        <Out item={credit} withoutText />
                      </div>
                    ))
                  )}
                  {accounts && (
                    accounts.map(({ type, name, ...account }, i) => (
                      <div className={[b.dFlex, b.justifyContentBetween].join(' ')} key={i}>
                        <Link to={resolveProductUrl({ bank: mysqlBank, ...account })}>
                          <div className={[b.dFlex, b.mr3].join(' ')}>
                            {type === 1 ? <Icon size={40} icon="Bank-account" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 2 ? <Icon size={40} icon="Piggy-bank" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 4 ? <Icon size={40} icon="Junior-account" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 5 ? <Icon size={40} icon="Branches" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 6 ? <Icon size={40} icon="Investment" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{name}</p>
                          </div>
                        </Link>
                        <Out item={account} withoutText />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={[b.colMd, mobileIsOpen === -1 ? "local-firm-mobile" : "local-firm-mobile-closed",].join(" ")}>
            <div className={[b.dFlex, b.justifyContentCenter].join(" ")}
              onClick={() => setMobileIsOpen(mobileIsOpen === -1 ? -2 : -1)} role="button" tabIndex={0} onKeyDown={setMobileIsOpen}>
              <div className={b.alignItemsCenter}>
                <Translate tKey="Products offered" />
                <button className={"btn-hidden"}>
                  {mobileIsOpen === -1 ? (
                    <Icon color="#0D2181" size={25} icon="Angle-down" className={[b.ml1, "icon-blog"].join(" ")} key="-1-up" />
                  ) : (
                    <Icon color="#0D2181" size={25} icon="Angle-up" className={[b.ml1, "icon-blog"].join(" ")} key="-1-down" />
                  )}
                </button>
              </div>
            </div>
            <div className={["local-firm-products", b.mt3, 'mobile-lf'].join(" ")}>
              {credits && (
                credits.map(({ type, name, ...credit }, i) => (
                  <div className={[b.dFlex, b.justifyContentBetween].join(' ')} key={i}>
                    <Link to={resolveProductUrl({ bank: mysqlBank, ...credit })}>
                      <div className={[b.dFlex, b.mr3].join(" ")}>
                        {type === 2 ? <Icon size={40} icon="Cash-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                        {type === 1 ? <Icon size={40} icon="Mortgage" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                        {type === 4 ? <Icon size={40} icon="Consolidation-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                        {type === 3 ? <Icon size={40} icon="Car-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                        {type === 5 ? <Icon size={40} icon="Deposits" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                        <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{name}</p>
                      </div>
                    </Link>
                    <Out item={credit} withoutText />
                  </div>
                ))
              )}
              {accounts && (
                accounts.map(({ type, name, ...account }, i) => (
                  <div className={[b.dFlex, b.justifyContentBetween].join(' ')} key={i}>
                    <Link to={resolveProductUrl({ bank: mysqlBank, ...account })}>
                      <div className={[b.dFlex, b.mr3].join(' ')}>
                        {type === 1 ? <Icon size={40} icon="Bank-account" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                        {type === 2 ? <Icon size={40} icon="Piggy-bank" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                        {type === 4 ? <Icon size={40} icon="Junior-account" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                        {type === 5 ? <Icon size={40} icon="Branches" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                        {type === 6 ? <Icon size={40} icon="Investment" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                        <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{name}</p>
                      </div>
                    </Link>
                    <Out item={account} withoutText />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default Bank;

export const query = graphql`
  query QueryBank($mysqlId: Int!, $promoUrl: String!) {
    allMysqlDescriptionBank(filter: { bank_id: { eq: $mysqlId }}) {
      nodes {
        header
        description
        name
      }
    }
    mysqlBank: mysqlBank(mysqlId: { eq: $mysqlId }) {
      description
      address
      advantages
      disadvantages
      domain
      swift
      rating
      workhours
      phone
      capital
      name
      prefix_url
      specific_url
      number_of_establishments
      url
      login_url
      established_year
      owner
      mysqlId
      app {
        url
        name
      }
      accounts {
        url
        prefix_url
        specific_url
        name
        type
        wniosker_id
        accounts_type {
          name
        }
      }
      credits {
        url
        prefix_url
        specific_url
        type
        name
        wniosker_id
        credit_type {
          name
        }
      }
      mysqlImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    allMysqlLocalfirms(
      filter: {
        bank_id: { eq: $mysqlId }
        type: { eq: 3 }
        parentId: { eq: 0 }
        isHeadquarters: { eq: 1 }
      }
    ) {
      group(field: address_city, limit: 1) {
        nodes {
          name
          url
        }
      }
    }
    faq: allMysqlFaq(
      filter: { item: { eq: $mysqlId }, type: { eq: "bank" }, answer: { ne: null } },
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        title: question
        text: answer
      }
    }
    bankPromotions: allMysqlBlog(filter: {promo_bonus: {ne: ""}, is_promo_end: { ne: 1 }, terms_path: {regex: $promoUrl}}) {
      totalCount
    }
    allSitePage(filter: {component: {glob: "**/bank-promo-list.js"}}) {
      nodes {
        path
      }
    }
    allMysqlComments(filter: {type: {eq: "bank"}, item: {eq: $mysqlId}}, sort: {order: DESC, fields: up}) {
      nodes {
        ...CommentData 
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "bank" }}) {
      nodes {
        ...SeoData
      }
    }
  }
`;
