import React, { createContext, useContext, useState, useEffect } from 'react'
import { ThemeToggler as ThemeMode } from 'gatsby-plugin-dark-mode'
import * as b from "./bootstrap.module.css"

export const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(typeof window !== 'undefined' ? localStorage.getItem('theme') || 'light' : 'light');
  
  useEffect(() => {
    setTheme(localStorage.getItem('theme') || 'light')
  }, [])
  
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

const ThemeToggle = () => {
  const { setTheme } = useContext(ThemeContext)

  return (
    <ThemeMode>
      {({ theme, toggleTheme }) => (
        <div className={["switch-input",b.dFlex].join(' ')}>
          <input type="checkbox" onChange={e => { 
            const newTheme = e.target.checked ? 'dark' : 'light';

            toggleTheme(newTheme);
            setTheme(newTheme);
          }} checked={theme === 'dark'} id="switch" name="theme" />
          <label htmlFor="switch" className={b.mb0}>
            <span className="sun-moon"/>
          </label>
        </div>
      )}
    </ThemeMode>
  )
}

export default ThemeToggle;