import React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import * as b from "./bootstrap.module.css"
import Thumbnail from './thumbnail'
import Out from "../components/out-product";
import resolveUrl from '../utils/urls/resolve-url';
import Icon from './icon'

const Posts = ({ items, bankpromo, children }) =>  (
  <div className="post-wrapper post-wrapper-column post-wrapper-post-column">
    {
      items.length > 0 && items.map(({ title, terms_path, term_normalized, image_description, url, mysqlImages, description, is_promo_end, thumbnail_canvas, published_date }, i) => {
        const postUrl = resolveUrl(terms_path, term_normalized, url);

        if (bankpromo) {
          return (
            <article key={i} className={[b.card, 'post'].join(' ')}>
              <div className={["bank-promotions",b.dFlex,b.justifyContentBetween,b.alignItemsCenter,b.p3].join(' ')}>
                <div className={[b.w50,b.mr2].join(' ')}>
                  <small>Bonus</small>
                  <div className={['fs25','bank-promo-bonus'].join(' ')}>{image_description}</div>
                </div>
                <div className={['bank-list-products-box',b.p3,b.w50,b.dFlex,b.alignItemsCenter,b.justifyContentCenter].join(' ')}>
                  <Thumbnail image={mysqlImages[0]} alt={image_description || ''}/>
                </div>
              </div>

              <div className="post-body">
                <div>
                  <Link to={postUrl} className={[b.dBlock, b.h6, b.fontWeightBold, b.mb3, "post-heading-color"].join(' ')}>{title}</Link>

                  <p className={['post-text', 'post-text-color', b.mb0].join(' ')}>
                    {description}
                  </p>
                </div>

                <div className={b.mt3}>
                  {is_promo_end && <div className={["firm-row-likes",b.dFlex,b.justifyContentCenter,b.alignItemsCenter].join(' ')}>
                    <Icon size={16} color="#B93838" icon="Info" className={['iconToWhite', b.mr1].join(' ')} />
                    <p className={['redToWhite', 'font-size-13',b.my0,'tp-name'].join(' ')} style={{ lineHeight: 0 }}>Promocja dobiegła końca</p>
                  </div>}

                  <Out className={[b.btn,"btn-danger","btn-normal", b.mb0,b.fontWeightNormal,'ranking-products-button', is_promo_end ? b.mt2 : ''].join(" ")} item={{ url: term_normalized }} defaultDescription="Take advantage of the promotion" defaultIcon="Logout"/>
                </div>
              </div>
            </article>
          )
        }

        return (
          <article key={i} className={[b.card, 'post'].join(' ')}>
            <Link to={postUrl} className="post-link">
              <img src={thumbnail_canvas?.replace('/op/', '/of/')} className='post-thumbnail' loading="lazy" alt={image_description || ''} />
              <div className={['blueToWhite','font-size-10',b.mx3,b.mt3,b.mb2].join(' ')}>{published_date}</div>
              <span className={[b.dBlock, b.h6, b.fontWeightBold, b.m3, "post-heading-color"].join(' ')}>{title}</span>
            </Link>

            <div className={["post-body", b.pt0].join(' ')}>
              <p className={['post-text', 'post-text-color', b.my0].join(' ')}>
                {description}
              </p>
            </div>
          </article>
        );
      })
    }

    {children}
  </div>
)

Posts.defaultProps = {
  bankpromo: false
}

Posts.propTypes = {
  items: PropTypes.array.isRequired,
  bankpromo: PropTypes.bool,
}

export default Posts

export const query = graphql`
  fragment PostData on MysqlBlog {
    title
    image_description
    url
    terms_path
    description
    term_normalized
    published_date
    thumbnail_canvas
    mysqlImages {
      childImageSharp {
        gatsbyImageData(width: 620)
      }
    }
  }
` 