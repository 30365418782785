import { graphql } from "gatsby";
import React from "react";
import * as b from "../components/bootstrap.module.css";
import Charger from "../components/charger";
import Icon from "../components/icon.tsx";
import Breadcrumbs from "../components/breadcrumbs"
import Translate from "../components/translate";
import BankListItem from "../components/items/bank-list-item";
import Layout from "../components/layout";
import BlogContent from "../components/blog-content";
import TableOfContents from "../components/table-of-contents";
import resolveUrl from "../utils/urls/resolve-url";
import Posts from "../components/posts";
import useMedia from "../hooks/useMedia";

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({ ...props }) => (
  <BaseHead {...props} description={props.data?.mysqlTerms?.description}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const BankList = ({ 
  data: { allMysqlBank, allMysqlBlog, bankPromotions, mysqlTerms, allSitePage }, 
  pageContext: { breadcrumbs, },
  location: { pathname },
}) => {
  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);
  return (
  <Layout pathname={pathname}>
      {isMobileResolution ? (
        <div className={['breadcrumbs-mobile-2', b.ptLg5, "scroll-inner"].join(' ')}>
          <Breadcrumbs items={breadcrumbs} theme='white'/>
        </div>
      )
      : (<div className={[b.container,'breadcrumbs-mobile-2', b.py3, b.ptLg5, "scroll-inner", b.px0].join(' ')}>
          <Breadcrumbs items={breadcrumbs} theme='white'/>
        </div>
        )
      }
    <div className={[b.container, b.pbLg5, b.pt3].join(" ")}>
      <div className={["blueToGray", b.mb4,b.mt2].join(" ")}>
        <h1 className={['big-title', b.mb2,b.mt4].join(" ")}>{mysqlTerms.title}</h1>
        <span className={[b.mb3,'font-size-20',b.fontWeightNormal].join(' ')}>
          {mysqlTerms.description && (
            <BlogContent content={mysqlTerms.description} className={[b.pr1, b.mb4].join(" ")}/>
          )}
        </span>
      </div>

      <Charger list={allMysqlBank.nodes} countItem={9999} step={8}>
        {({ loadMore, isLoaded, customList }) => (
          <>
           <div className={b.row}>
            {customList.length > 0 && customList.map((props, i) => { 
              const bankPromoUrl = bankPromotions.nodes.some(({ terms_path }) => terms_path.includes(props.specific_url || props.url)) 
                ? allSitePage.nodes.filter(page => page.path.includes(resolveUrl(props.url)))[0]?.path
                : '';

              return (
                <BankListItem {...props} key={i} bankPromoUrl={bankPromoUrl}/>
              )
            })}
          </div>
            {isLoaded && (
              <button onClick={loadMore} className={[b.btn,'btn-light','btn-normal','button-256',b.my5,b.mxAuto].join(' ')}>
                <Icon size={20} icon="Buffering" className={[b.mr1, "icon-blog"].join(" ")}/>
                <Translate tKey="Load more banks"/>
              </button>
            )}
          </>
        )}
      </Charger>
 
      <div className={`${b.pt3} ${b.ptLg4}`}>
        <div className={["blog-content", b.row, b.mtLg3,'blueToGray', 'dark-li'].join(" ")}>
          <div className={[ b.colXl8, 'dark-theme-listing'].join(' ')}>
          {mysqlTerms.description_alt && (
            <BlogContent content={mysqlTerms.description_alt} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          {mysqlTerms.description_additional && (
            <BlogContent content={mysqlTerms.description_additional} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          </div>
          <TableOfContents selector=".post-content h2[id]" />
        </div>
      </div>
    </div>

    {allMysqlBlog.nodes.length !== 0 && ( <>
      <section className={`${b.container} ${b.pt3} ${b.ptLg4} ${b.pb4} ${b.pbLg5} ${b.mbLg5}`}>
        <h2 className={["title", b.dBlock, b.mb4,b.mt3].join(' ')}>Treści powiązane z {mysqlTerms.term}</h2>
        <Posts items={allMysqlBlog.nodes} />
      </section>
    </>)}
  </Layout>
)};

export default BankList;

export const query = graphql`
  query BankList($mysqlId: Int!, $withoutUrls: [String]) {
    allMysqlBank(filter: {visible: {eq: 1}, form_visible: {eq: 1}}) {
      nodes {
        name
        prefix_url
        specific_url
        url
        inactive_reason
        rating
        mysqlImage {
          childImageSharp {
            gatsbyImageData(width: 130)
          }
        }
        accounts {
          url
          prefix_url
          specific_url
          type
          accounts_type {
            name
          }
        }
        credits {
          url
          prefix_url
          specific_url
          type
          credit_type {
            name
          }
        }
        card {
          url
          prefix_url
          specific_url
        }
      }
    }
    allMysqlBlog(filter: {url: {nin: $withoutUrls}, blogTerms:{elemMatch:{type: {eq: "category"}, mysqlId: {eq: $mysqlId}}}, description: {ne: null}, status: {eq: "publish"}}) {
      nodes {
        ...PostData
      }
    }
    mysqlTerms(mysqlId: {eq: $mysqlId}) {
      description_additional
      description_alt
      description
      title
      term
    }
    bankPromotions: allMysqlBlog(filter: {promo_bonus: {ne: ""}, is_promo_end: { ne: 1 }}) {
      nodes {
        terms_path
      }
    }
    allSitePage(filter: {component: {glob: "**/bank-promo-list.js"}}) {
      nodes {
        path
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
      nodes {
        ...SeoData
      }
    }
  }
`;
