const React = require('react');
const { ComparisionProvider } = require('./src/components/comparision-context');
const { ThemeProvider } = require("./src/components/theme-toggle");
const { TranslateProvider } = require("./src/components/translate");

exports.wrapRootElement = ({ element }) =>  (
  <TranslateProvider>
    <ComparisionProvider>
      <ThemeProvider>
        {element}
      </ThemeProvider>
    </ComparisionProvider>
  </TranslateProvider>
);