import React, { useMemo, useState } from 'react';
import * as b from "../../../bootstrap.module.css";
import InputNumber from '../../input-number';
import AllProducts from '../../../all-products';
import { ExcelFormulas } from "../../../../utils/excel-formulas";
import Icon from '../../../icon';

const MortgageCalculator = () => {
  const [amount, setAmount] = useState(100_000);
  const [period, setPeriod] = useState(120);
  const [interest, setInterest] = useState(9);
  const [commission, setCommission] = useState(4);
  const [contribution, setContribution] = useState(10);
  const [isExtendedView, setIsExtendedView] = useState(false);
  const [details, setDetails] = useState(null);

  const settings = useMemo(() => ({
    types: 'credits', 
    subtypes: { credits: '1' },
    input: { 
      amount, 
      time: period
    },
  }), [amount, period]);

  const clearDetails = (e) => {
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'BUTTON') {
      setDetails(null);
    }
  };

  const updateDetails = () => {
    setDetails(calculate(amount, period, interest, commission));
  };

  const toggleExtendedView = (e) => {
    setIsExtendedView(!isExtendedView);
    clearDetails(e);
  };

  return (
    <form 
      className={["product-inputs", "credit-calculator", "blueToGray", b.mt4, b.p3].join(" ")} 
      onSubmit={(e) => e.preventDefault()}
    >
      <h2 className={b.mb3} style={{ fontWeight: 500 }}>Jak obliczyć koszt i wysokość raty kredytu hipotecznego?</h2>

      <div className={b.row}>
        <div 
          className={[b.colMd6, b.colXl4, b.order1].join(' ')} 
          onFocusCapture={clearDetails}
        >
          <label htmlFor="amount" className={b.mb2}>
            Kwota kredytu:
          </label>

          <InputNumber
            id="amount"
            minValue={10_000}
            value={amount} 
            maxValue={1_000_000}
            updateValue={setAmount} 
            step={100}
            suffix="zł"
          />
        </div>

        <div 
          className={[b.colMd6, b.colXl4, b.order2, b.mt3, b.mtMd0].join(' ')}
          onFocusCapture={clearDetails}
        >
          <label htmlFor="period" className={b.mb2}>
            Liczba miesięcznych rat:
          </label>

          <InputNumber
            id="period"
            minValue={12}
            value={period} 
            maxValue={360}
            updateValue={setPeriod} 
            step={1}
            suffix="mies"
          />
        </div>

        <div className={[b.colMd12, b.colXl4, b.orderLast, b.orderXl3, b.alignSelfEnd, b.mt3, b.mtXl0].join(' ')}>
          <button 
            className={[b.btn, b.btnSuccess, "btn-success", "btn-cta", b.my0].join(" ")}
            onClick={updateDetails}
          >
            <Icon size={25} icon="Calculator-mini" className={[b.mr2, "icon-width"].join(" ")} />

            Oblicz
          </button>
        </div>

        <div className={[b.col12, b.order3, b.mt3].join(' ')}>
          <button className="toggle-calculator-view blueToGray" onClick={toggleExtendedView}>
            {isExtendedView ? 'Ustawienia podstawowe' : 'Ustawienia zaawansowane'}

            {isExtendedView && <Icon size={17} icon="Angle-up" className={['icon-blog', b.ml1, b.mr0].join(' ')} />}
            {!isExtendedView && <Icon size={17} icon="Angle-down" className={['icon-blog', b.ml1, b.mr0].join(' ')} />}
          </button>
        </div>

        {isExtendedView && <>
          <div 
            className={[b.colMd6, b.colXl4, b.order5, b.mt2].join(' ')}
            onFocusCapture={clearDetails}
          >
            <label htmlFor="interest" className={b.mb2}>
              Oprocentowanie:       
            </label>

            <InputNumber
              id="interest"
              minValue={0.01}
              value={interest} 
              maxValue={30}
              updateValue={setInterest} 
              step={0.01}
              suffix="%"
            />
          </div>
       
          <div 
            className={[b.colMd6, b.colXl4, b.order6, b.mt3, b.mtMd2].join(' ')}
            onFocusCapture={clearDetails}
          >
            <label htmlFor="commission" className={b.mb2}>
              Prowizja:     
            </label>

            <InputNumber
              id="commission"
              minValue={0.01}
              value={commission} 
              maxValue={30}
              updateValue={setCommission} 
              step={0.01}
              suffix="%"
            />
          </div>
       
          <div 
            className={[b.colMd6, b.colXl4, b.order6, b.mt3, b.mtMd2].join(' ')}
            onFocusCapture={clearDetails}
          >
            <label htmlFor="contribution" className={b.mb2}>
              Wkład własny ({ contributionAmount(amount, contribution) } zł):     
            </label>

            <InputNumber
              id="contribution"
              minValue={10}
              value={contribution} 
              maxValue={70}
              updateValue={setContribution} 
              step={1}
              suffix="%"
            />
          </div>
        </>}
      </div>

      {details && (<>
        <div className={["details", b.mt4, b.p3].join(" ")}>
          <div className={b.w100}>
            <h3 className={[b.textLeft, b.mb3].join(' ')}>Wyniki obliczeń</h3>
            
            <p className={b.my1}><strong style={{ fontSize: '20px' }}>Wysokość raty: {details.installment.toFixed(2)} zł</strong></p>
            <p className={b.my1}>Odsetki: <strong>{details.cost.toFixed(2)} zł</strong></p>
            <p className={b.my1}>Całkowita kwota do spłaty: <strong>{details.total.toFixed(2)} zł</strong></p>

            <div className={[b.row, b.alignItemsLgCenter, b.mt4].join(" ")}>
              <div className={[b.colAuto, b.pr0, "iconToWhite"].join(' ')}>
                <Icon color="#4EAA97" size={25} icon="Info" className={"icon-blog"} />
              </div>

              <div className={[b.col, b.pl2].join(' ')}>
                Kalkulator kredytowy przedstawia orientacyjne koszty kredytu i nie przedstawia oferty w rozumieniu art. 66 KC. Ostateczna wysokość raty, poszczególnych kosztów zależy od oferty kredytodawcy. Szansa na otrzymanie kredytu jest wynikiem indywidualnej oceny zdolności kredytowej.
              </div>
            </div>
          </div>
        </div>

        <h3 className={[b.mt4, b.mb3].join(' ')}>Zobacz oferty na kwotę {amount} zł i okres spłaty {period} miesięcy</h3>

        <AllProducts settings={settings} />
      </>)}
    </form>
  );
};

const calculate = (amount, period, interest, commission) => {
  const percentage = interest / 100;
  const provision = commission / 100;

  const installment = Math.round(
    ExcelFormulas.PMT(
      percentage / 12, period, amount + amount * provision
    ) * -100
  ) / 100;
  const total = Math.round(installment * period * 100) / 100;
  const cost = Math.round((total - amount) * 100) / 100;
  
  return {
    total,
    cost,
    installment,
  };
}

const contributionAmount = (amount, percent) => {
  return parseInt(amount * percent / 100);
};

export default MortgageCalculator;