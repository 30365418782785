import React, { useState } from 'react'
import { Link } from 'gatsby'
import * as b from "../components/bootstrap.module.css"
import RankingWidget from '../components/ranking-widget'
import Icon from '../components/icon'
import Layout from '../components/layout'

import BaseHead from '../components/base-head';
export const Head = ({ ...props }) => (
  <BaseHead {...props}
    title="Porównywarka kredytów i pożyczek online"
    description="Platforma ofin.pl pozwala bezpłatnie sprawdzić koszty kredytów, pożyczek i kont bankowych. Wszystko online i za darmo!"
  />
);

const decimal = new Intl.NumberFormat('fr', { 
  style: 'decimal',
});

const moneyFormat = (value) => `${decimal.format(value)} zł`;

const IndexPage = ({
  location: { pathname }
}) => {
  const [amount, setAmount] = useState(10000);
  const [numberAmountValue, setNumberAmountValue] = useState('');

  const changeAmounts = (value) => () => {
    setAmount(value);

    if (numberAmountValue !== '') {
      setNumberAmountValue(moneyFormat(value));
    }
  };

  return (
    <Layout pathname={pathname}>
      <div className={['intro', b.py0, b.mb4, b.mbLg5].join(' ')}>
        <div className={[b.container, b.pyLg5, b.py3].join(' ')}>
          <div className={[b.row, b.justifyContentBetween].join(' ')}>
            <div className={[b.colLg5, b.colXl4].join(' ')}>
              <h1 className={[b.mbLg5,b.mb3,'tp-name',b.textWhite].join(' ')}>Poznaj porównywarkę pożyczek Ofin!</h1>
              <div className={['home-page-app-box', b.p4].join(' ')}>
                <span className={['font-size-22','blueToWhite'].join(' ')}>Ile potrzebujesz?</span>
                <div className={b.row}>
                  <div className={[b.colLg6,b.mt2,b.prLg1].join(' ')}>
                    <input type="button" value="2 000 zł" className={['home-page-ghost-btn', amount === 2000 ? 'active' : ''].join(' ')} onClick={changeAmounts(2000)} />
                  </div>
                  <div className={[b.colLg6,b.mt2,b.plLg1].join(' ')}>
                    <input type="button" value="5 000 zł" className={['home-page-ghost-btn', amount === 5000 ? 'active' : ''].join(' ')} onClick={changeAmounts(5000)} />
                  </div>
                  <div className={[b.colLg6,b.mt2,b.prLg1].join(' ')}>
                    <input type="button" value="10 000 zł" className={['home-page-ghost-btn', amount === 10000 ? 'active' : ''].join(' ')} onClick={changeAmounts(10000)} />
                  </div>
                  <div className={[b.colLg6,b.mt2,b.plLg1].join(' ')}>
                    <input type="button" value="20 000 zł" className={['home-page-ghost-btn', amount === 20000 ? 'active' : ''].join(' ')} onClick={changeAmounts(20000)} />
                  </div>
                </div>
                <div className={[b.dFlex,b.justifyContentCenter,'blackToWhite',b.mtLg4,b.my3].join(' ')}>
                  <input 
                    type="text"
                    id="amount"
                    inputMode="numeric"
                    value={numberAmountValue}
                    min={1000} 
                    max={150_000}
                    step={1000}
                    placeholder="Podaj własną wartość"
                    onChange={() => {
                      // 
                    }}
                    onInput={(e) => {
                      setNumberAmountValue(Number.parseInt(e.target.value, 10) || 0);
                    }}
                    onFocus={() => {
                      setNumberAmountValue(amount);
                    }}
                    onBlur={(e) => {
                      let value = Number.parseInt(e.target.value, 10);

                      if (!Number.isSafeInteger(value) || value < 1000) {
                        value = 1000;
                      } else if (value > 150_000) {
                        value = 150_000;
                      } else if (value % 1000) {
                        value = value - (value % 1000);
                      }

                      changeAmounts(value)();
                    }}
                    className='styled-number'
                  />
                </div>

                <a href={`https://out.ofin.pl/wniosek/kredyt?amount=${amount}`} target='_blank' className={[b.btn, 'btn-success', 'btn-normal', b.mtLg4, b.mt3, b.fontWeightNormal].join(' ')}>
                  <Icon size={20} color="#FFFFFF" icon="Taking-money" className={b.mr2} />
                  Wypełnij wniosek
                </a>
                <small className={['font-size-9', 'blackToWhite', 'home-page-calc-desc', b.mt2].join(' ')}>
                  RRSO wynosi <b>13,20%</b>, całkowita kwota kredytu <b>42 300 zł</b>, całkowita kwota do zapłaty <b>64 224,80 zł</b>, oprocentowanie zmienne <b>9,39%</b>, całkowity koszt kredytu <b>21 924,80 zł</b> (w tym: prowizja 0 zł, odsetki 21 924,80 zł), 86 rat miesięcznych w wysokości <b>746,80 zł</b>. Kalkulacja została dokonana na dzień 19.11.2022 na reprezentatywnym przykładzie kredytu gotówkowego VeloBank.
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RankingWidget types="all" theme="column" />

      <div className={["home-page-white-bg"].join(' ')}>
        <div className={['home-page-grey-box',b.mbLg5,b.mb3].join(' ')}>
          <div className={[b.container, 'blue-box-px0'].join(' ')}>
            <h2>Wybierz produkt dla siebie!</h2>
            <p className={['blueToWhite','font-size-20',b.mb0,b.pt1].join(' ')}>
              Szukasz konkretnego produktu? Wybierz to czego potrzebujesz i porównaj propozycje dostępne w bankach i firmach pożyczkowych. Przedstawiamy najważniejsze informacje i opinie klientów!
            </p>
            <div className={[b.row,b.mtLg5].join(' ')}>
              <div className={[b.colLg2,b.prLg2,b.mt2].join(' ')}>
                <Link to="/chwilowki/" className={['home-page-app-box',b.dFlex,b.alignItemsCenter].join(' ')}>
                  <Icon size={40} icon="Loans" className={[b.mr2,'icon-blog'].join(' ')} />
                  Chwilówka
                </Link>
              </div>
              <div className={[b.colLg2,b.pxLg2,b.mt2].join(' ')}>
                <Link to="/kredyty-gotowkowe/" className={['home-page-app-box',b.dFlex,b.alignItemsCenter].join(' ')}>
                  <Icon size={40} icon="Cash-loan" className={[b.mr2,'icon-blog'].join(' ')} />
                  Kredyt gotówkowy
                </Link>
              </div>
              {/* <div className={[b.colLg2,b.px2,b.mt2].join(' ')}>
                <div className={['home-page-app-box',b.dFlex,b.alignItemsCenter].join(' ')}>
                  <Icon size={40} icon="Car-loan" className={[b.mr2,'icon-blog'].join(' ')} />
                  Kredyt samochodowy
                </div>
              </div> */}
              <div className={[b.colLg2,b.pxLg2,b.mt2].join(' ')}>
                <Link to="/kredyty-konsolidacyjne/" className={['home-page-app-box',b.dFlex,b.alignItemsCenter].join(' ')}>
                  <Icon size={40} icon="Consolidation-loan" className={[b.mr2,'icon-blog'].join(' ')} />
                  Kredyt konsolidacyjny
                </Link>
              </div>
              {/* <div className={[b.colLg2,b.px2,b.mt2].join(' ')}>
                <div className={['home-page-app-box',b.dFlex,b.alignItemsCenter].join(' ')}>
                  <Icon size={40} icon="Credit-cards" className={[b.mr2,'icon-blog'].join(' ')} />
                  Karty kredytowe
                </div>
              </div> */}
              <div className={[b.colLg2,b.pxLg2,b.mt2].join(' ')}>
                <Link to="/konta-bankowe/" className={['home-page-app-box',b.dFlex,b.alignItemsCenter].join(' ')}>
                  <Icon size={40} icon="Bank-account" className={[b.mr2,'icon-blog'].join(' ')} />
                  Konta bankowe
                </Link>
              </div>
              <div className={[b.colLg2,b.prLg2,b.mt2].join(' ')}>
                <Link to="/kredyty-hipoteczne/" className={['home-page-app-box',b.dFlex,b.alignItemsCenter].join(' ')}>
                  <Icon size={40} icon="Mortgage" className={[b.mr2,'icon-blog'].join(' ')} />
                  Kredyt hipoteczny
                </Link>
              </div>
              <div className={[b.colLg2,b.pxLg2,b.mt2].join(' ')}>
                <Link to="/pozyczki-ratalne/" className={['home-page-app-box',b.dFlex,b.alignItemsCenter].join(' ')}>
                  <Icon size={40} icon="Installment-loan" className={[b.mr2,'icon-blog'].join(' ')} />
                  Pożyczka ratalna
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={[b.pyLg5,b.py3].join(' ')}>
          <div className={b.container}>
            <div className={b.row}>
              <div className={b.colLg6}>
                <h2>Dlaczego warto wybrać porównywarkę ofert finansowych ofin.pl?</h2>
                <p className={['font-size-20','blueToWhite',b.pt1,b.mb0].join(' ')}>
                  Ofin zapewnia proste wnioskowanie i błyskawiczną odpowiedź z gotową propozycją pożyczki lub kredytu. Powodów jest mnóstwo, ale wybraliśmy te najważniejsze!
                </p>
              </div>
            </div>
            <div className={[b.row,b.mtLg5,b.mt3].join(' ')}>
              <div className={[b.colLg4].join(' ')}>
                <div className={['home-page-app-box',b.p3,b.dFlex,b.alignItemsCenter,b.h100].join(' ')}>
                  <Icon size={56} icon="Cash-loan" className={[b.mr3,'icon-blog'].join(' ')} />
                  <div>
                    <span className={['font-size-24','blackToWhite'].join(' ')}>Oferta nawet w 15 minut!</span>
                    <p className={['blueToWhite',b.mt2].join(' ')}>W kilka minut otrzymasz propozycję pożyczki dostępną od ręki!</p>
                  </div>
                </div>
              </div>
              <div className={[b.colLg4,].join(' ')}>
                <div className={['home-page-app-box',b.p3,b.dFlex,b.alignItemsCenter,b.h100].join(' ')}>
                  <Icon size={56} icon="Coins" className={[b.mr3,'icon-blog'].join(' ')} />
                  <div>
                    <span className={['font-size-24','blackToWhite'].join(' ')}>100% online, bez wychodzenia z domu!</span>
                    <p className={['blueToWhite',b.mt2].join(' ')}>Wszystkie formalności załatwisz przez Internet - w dowolny dzień, o każdej porze!</p>
                  </div>
                </div>
              </div>
              <div className={[b.colLg4,].join(' ')}>
                <div className={['home-page-app-box',b.p3,b.dFlex,b.alignItemsCenter,b.h100].join(' ')}>
                  <Icon size={56} icon="Cell-phone" className={[b.mr3,'icon-blog'].join(' ')} />
                  <div>
                    <span className={['font-size-24','blackToWhite'].join(' ')}>Gwarancja bezpieczeństwa!</span>
                    <p className={['blueToWhite',b.mt2].join(' ')}>Twoje dane są szyfrowane i używane tylko w celu dopasowania oferty!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={["home-page-blue-box",b.myLg5,b.my3, 'introMainPage'].join(' ')}>
          <div className={[b.container, 'blue-box-px0'].join(' ')}>
            <div className={b.row}>
              <div className={b.colLg8}>
                <h2 className={[b.my2, b.mbLg4, 'title'].join(' ')}>Masz pytanie? Potrzebujesz porady pożyczkowej? Zadzwoń!</h2>
                <span className={['font-size-20',b.textWhite].join(' ')}>Nasi eksperci wypełnią wniosek w Twoim imieniu i pomogą znaleźć pożyczkę! Nie czekaj, zadzwoń już teraz!</span>
                
                <p className={b.my3}>
                  <Icon size={56} icon="Cell-phone" className={[b.mr3,'icon-width'].join(' ')} />
                  <a href="tel:+48585004307" className={[b.dBlock, b.dSmInline].join(' ')} style={{ color: 'inherit', textDecoration: 'none' }}>
                    585 004 307
                  </a>
                </p>

                <div className={[b.row].join(' ')}>
                  <div className={[b.colMd,b.dFlex,b.textWhite,b.alignItemsCenter].join(' ')}>
                    <Icon size={12} icon="Checked-green-circle" className={[b.mr3,'icon-width'].join(' ')} /> 
                    Profesjonalne doradztwo
                  </div>
                  <div className={[b.colMd,b.dFlex,b.textWhite,b.alignItemsCenter].join(' ')}>
                    <Icon size={12} icon="Checked-green-circle" className={[b.mr3,'icon-width'].join(' ')} />
                    Błyskawiczna oferta
                  </div>
                  <div className={[b.colMd,b.dFlex,b.textWhite,b.alignItemsCenter].join(' ')}>
                    <Icon size={12} icon="Checked-green-circle" className={[b.mr3,'icon-width'].join(' ')} />
                    Minimum formalności
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
